import { useContext, useEffect } from 'react';
import { AppContext } from '../../App';
import { useParams } from 'react-router-dom';
import MaterialReactTable, {
  MaterialReactTableProps,
} from 'material-react-table';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../../api/api';
import jsoncParser from '../../utils/jsoncParser';

export default function Instrument() {
  const {
    appState: {
      instrumentsData: {
        instruments,
        currentInstrument,
        instrumentConfigData,
      },
      userData,
    },
    appDispatch,
  } = useContext(AppContext);

  const {hwId} = useParams();

  useEffect(() => {
    const currentInstrument: any = instruments.find((instrument) => {
      return instrument.hardwareId === hwId;
    });

    const {
      instrumentSn: instrumentSN,
      clientKey,
    } = currentInstrument;

    const configHistory = currentInstrument.configHistory;
    let recentConfigData;
    let recentInstrumentSN;
    let recentClientKey;

    let configInstrumentSN;
    let configClientKey;

    if (configHistory.length) {
      recentConfigData = configHistory[configHistory.length - 1];
    }

    if (recentConfigData?.config) {
      recentInstrumentSN = recentConfigData.config.instrumentSn;
      recentClientKey = recentConfigData.config.clientKey;
    }

    configInstrumentSN = (recentInstrumentSN && recentClientKey) ? recentInstrumentSN : instrumentSN;
    configClientKey = (recentInstrumentSN && recentClientKey) ? recentClientKey : clientKey;

    let instrumentConfigData: Record<string, any> | null = null;

    if (configInstrumentSN && configClientKey) {
      api.firebase.getInstrumentConfig({
        instrumentSN: configInstrumentSN,
        clientKey: configClientKey,
      })
      .then((resp) => {
        if (resp.data) {
          instrumentConfigData = jsoncParser(resp.data);
          // console.log('instrumentConfigData', instrumentConfigData);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        appDispatch({
          type: 'SET_INSTRUMENTS_DATA',
          payload: {
            currentInstrument,
            instrumentConfigData,
          }
        });
      })
    }
  }, [appDispatch, hwId, instruments]);

  const UNDEFINED_SIGN = 'Undefined';

  const tableHeaders = [
    {
      header: 'Client Key',
      accessorKey: 'clientKey',
    },
    {
      header: 'Instrument Type',
      accessorKey: 'instrumentType',
    },
    {
      header: 'Instrument SN',
      accessorKey: 'instrumentSn',
    },
    {
      header: 'Hardware ID',
      accessorKey: 'hardwareId',
      enableEditing: false,
    },
    {
      header: 'Updated At',
      accessorKey: 'modifiedAt',
      enableEditing: false,
    },
    {
      header: 'Web',
      accessorKey: 'web',
      enableEditing: false,
    },
    {
      header: 'Local IP',
      accessorKey: 'localIp',
      enableEditing: false,
    },
    {
      header: 'SSH',
      accessorKey: 'ssh',
      enableEditing: false,
    },
    {
      header: 'Logs',
      accessorKey: 'logs',
      enableEditing: false,
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      enableEditing: false,
    },
  ];

  let wrkData: any[] = [];

  wrkData = currentInstrument ? [currentInstrument].map((instrumentInfo: Record<string, any>) => {
    const {
      hardwareId,
      web,
      localIp,
      ssh,
      logs,
      createdAt,
      configHistory,
    } = instrumentInfo;

    const recentConfig = configHistory[configHistory.length - 1];

    return {
      instrumentType: recentConfig.config.instrumentType || UNDEFINED_SIGN,
      hardwareId,
      clientKey: recentConfig.config.clientKey || UNDEFINED_SIGN,
      web,
      instrumentSn: recentConfig.config.instrumentSn || UNDEFINED_SIGN,
      localIp,
      ssh,
      logs,
      createdAt: new Date(createdAt).toLocaleString(),
      modifiedAt: new Date(recentConfig.createdAt).toLocaleString(),
    }
  }) : [];

  let wrkDataFromInstrument: any[] = [];

  if (wrkData.length) {
    wrkDataFromInstrument = [{
      ...wrkData[0],
      instrumentType: currentInstrument?.instrumentType || UNDEFINED_SIGN,
      clientKey: currentInstrument?.clientKey || UNDEFINED_SIGN,
      instrumentSn: currentInstrument?.instrumentSn || UNDEFINED_SIGN,
      modifiedAt: new Date(currentInstrument!.modifiedAt).toLocaleString(),
    }];
  }

  const handleSaveRowEdits: MaterialReactTableProps<any>['onEditingRowSave'] = async ({ exitEditingMode, row, values }) => {
    const {
      hardwareId,
      instrumentSn,
      instrumentType,
      clientKey,
    } = values;

    const currentInstrument = instruments.find((instrument) => {
      return instrument.hardwareId === hardwareId;
    });

    if (!currentInstrument) {
      return
    }

    let configMd5;

    if (currentInstrument?.configHistory?.length) {
      configMd5 = currentInstrument.configHistory[currentInstrument.configHistory.length - 1].config.configMd5;
    }
  
    await api.firebase.updateInstrument({
      user: userData?.email || 'N/A',
      hardwareId,
      instrumentSn,
      instrumentType,
      clientKey,
      configMd5: configMd5 || '',
    })
    .catch(err => {
      console.log(err);

      appDispatch({
        type: 'SET_ERROR',
        payload: err.message,
      });
    });

    // Previous update logic, Delete when all test will be completed

    // const {
    //   instrumentSn: updatedInstrumentSn,
    //   instrumentType: updatedinstrumentType,
    //   clientKey: updatedclientKey,
    // } = apiResp.data;

    // const updatedInstrumentObj = {
    //   ...currentInstrument,
    //   ...{
    //     instrumentSn: updatedInstrumentSn,
    //     instrumentType: updatedinstrumentType,
    //     clientKey: updatedclientKey,
    //   },
    // };

    // appDispatch({
    //   type: 'SET_INSTRUMENTS_DATA',
    //   payload: {
    //     currentInstrument: updatedInstrumentObj,
    //   }
    // });

    // Refreshing list of instruments after md5 update
    const updatedInstruments = await api.firebase.getInstruments()
    .catch(err => {
      appDispatch({
        type: 'SET_ERROR',
        payload: err?.response?.data?.message || err.message,
      });
    });

    if (updatedInstruments.data && updatedInstruments?.data?.instruments) {
      appDispatch({
        type: 'SET_INSTRUMENTS_DATA',
        payload: {
          instruments: updatedInstruments.data.instruments,
        }
      })
    } else {
      appDispatch({
        type: 'SET_ERROR',
        payload: 'Can\'t update list of instruments' ,
      });
    }

    appDispatch({
      type: 'SET_SUCCESS',
      payload: 'Instrument config successfully updated'
    })

    exitEditingMode(); //required to exit editing mode
  };
  
  // Event listener function for FileReader()
  const processConfigUpload = async (event: any, fileSrc: Blob) => {
    const rawConfigTextBody = event.target.result;

    if(!rawConfigTextBody || !currentInstrument) {
      return;
    }

    const convertedConfig = jsoncParser(rawConfigTextBody);

    if (!convertedConfig) {
      return appDispatch({
        type: 'SET_ERROR',
        payload: 'Can\'t process .jsonc file',
      });
    }

    // console.log('convertedConfig', convertedConfig);

    if (
      !convertedConfig?.instrument_sn ||
      !convertedConfig?.client_id ||
      !convertedConfig?.instrument_type
    ) {
      return appDispatch({
        type: 'SET_ERROR',
        payload: 'Error in config file structure. Required field(s) are missing',
      });
    }

    const {
      instrument_sn: instrumentSn,
      client_id: clientKey,
      instrument_type: instrumentType,
    } = convertedConfig;

    const {
      hardwareId,
    } = currentInstrument;

    const formData = new FormData();
    formData.append('configFile', fileSrc);

    const updatedConfigFileResp = await api.firebase.updateInstrumentConfig(formData, {
      instrumentSn,
      clientKey,
    })
    .catch(err => {
      console.log(err);

      appDispatch({
        type: 'SET_ERROR',
        payload: err.message,
      });
    });

    const newConfigMd5 = updatedConfigFileResp?.data?.md5;

    if (!newConfigMd5) {
      return appDispatch({
        type: 'SET_ERROR',
        payload: 'Can\'t update config MD5 due to server error' ,
      });
    }

    // Saving new md5 to current instrument config
    await api.firebase.updateInstrument({
      user: userData?.email || 'N/A',
      hardwareId,
      instrumentSn,
      instrumentType,
      clientKey,
      configMd5: newConfigMd5,
    })
    .catch(err => {
      console.log(err);

      appDispatch({
        type: 'SET_ERROR',
        payload: err.message,
      });
    });

    // Refreshing list of instruments after md5 update
    const updatedInstruments = await api.firebase.getInstruments()
      .catch(err => {
        appDispatch({
          type: 'SET_ERROR',
          payload: err?.response?.data?.message || err.message,
        });
      });

    if (updatedInstruments.data && updatedInstruments?.data?.instruments) {
      appDispatch({
        type: 'SET_INSTRUMENTS_DATA',
        payload: {
          instruments: updatedInstruments.data.instruments,
        }
      })
    } else {
      return appDispatch({
        type: 'SET_ERROR',
        payload: 'Can\'t update list of instruments' ,
      });
    }

    appDispatch({
      type: 'SET_SUCCESS',
      payload: 'Instrument config file successfully updated'
    });
  }

  const handleConfigUploadWithCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.currentTarget?.files;

    if(!files || !currentInstrument) {
      return;
    }

    const configFile = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      (event) => {
        processConfigUpload(event, configFile);
      },
      false
    );

    reader.readAsText(configFile); 
  }

  const refreshInstrumentData = async () => {
    const updatedInstruments = await api.firebase.getInstruments()
      .catch(err => {
        appDispatch({
          type: 'SET_ERROR',
          payload: err?.response?.data?.message || err.message,
        });
      });

      if (updatedInstruments.data && updatedInstruments?.data?.instruments) {
        appDispatch({
          type: 'SET_INSTRUMENTS_DATA',
          payload: {
            instruments: updatedInstruments.data.instruments,
          }
        });

        appDispatch({
          type: 'SET_SUCCESS',
          payload: 'Data refreshed'
        });
      } else {
        appDispatch({
          type: 'SET_ERROR',
          payload: 'Can\'t update list of instruments' ,
        });
      }
  }

  return (
    <Box>
      <Paper sx={{ borderRadius: '4px 4px 0 0' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              padding: '16px 0 24px 8px',
              marginRight: 'auto',
            }}
          >
            Data in Database / Data from instrument
          </Typography>
          <Button
            color="success"
            variant="contained"
            disableElevation
            endIcon={<RefreshIcon />}
            sx={{
              marginLeft: 'auto',
            }}
            onClick={refreshInstrumentData}
          >
            Refresh data from instrument
          </Button>
        </Box>
        <MaterialReactTable
          columns={tableHeaders}
          data={wrkData}
          enableColumnOrdering={false}
          enableGlobalFilter={false}
          enableColumnFilters={false}
          enableColumnActions={false}
          enableColumnFilterModes={false}
          onEditingRowSave={handleSaveRowEdits}
          initialState={{
            ...{
              density: 'compact',
              pagination: {pageSize: 50, pageIndex: 0},
            }
          }}
          editingMode="row"
          enableEditing={false}
          enableTableFooter={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          enableRowActions={false}
        />
        <MaterialReactTable
          columns={tableHeaders}
          data={wrkDataFromInstrument}
          enableColumnOrdering={false}
          enableGlobalFilter={false}
          enableColumnFilters={false}
          enableColumnActions={false}
          enableColumnFilterModes={false}
          initialState={{
            ...{
              density: 'compact',
              pagination: {pageSize: 50, pageIndex: 0},
            }
          }}
          editingMode="row"
          enableEditing={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          enableTableHead={false}
          enableRowActions={false}
        />
        <Box
          sx={{
            display: 'flex',
            padding: '16px 0 16px 8px'
          }}
        >
          <Button
            color="success"
            variant="contained"
            disableElevation
            endIcon={<DownloadIcon />}
            component="label"
            disabled={!currentInstrument}
            // onClick={!userData ? loginAlert : undefined}
          >
            Upload config file
            <input
              hidden
              type="file"
              onChange={handleConfigUploadWithCheck}
              accept=".jsonc"
            />
          </Button>
        </Box>
        {Boolean(instrumentConfigData) && <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-config"
            >
              View Current Config File
            </AccordionSummary>
            <AccordionDetails>
              <pre>{JSON.stringify(instrumentConfigData, null, 2) }</pre>
            </AccordionDetails>
          </Accordion>
        </Box>}
      </Paper>
    </Box>
  );
}