import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface TChartItem {
  color: string;
  value: string;
  tooltip?: string;
  isTitleItem?: boolean;
};

export default function ChartItem(props: TChartItem) {
  const {
    color,
    value,
    tooltip,
    isTitleItem,
  } = props;

  return (
    <Tooltip
      title={tooltip}
      placement="top"
      arrow
    >
      <Box sx={{
        flexGrow: 1,
        minWidth: 30,
        height: 30,
        maxWidth: isTitleItem ? 30 : 'none',
        lineHeight: '30px',
        textAlign: 'center',
        backgroundColor: color,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fff',
        },
      }}>
        <Typography variant="caption" component="span">
          {value}
        </Typography> 
      </Box>
    </Tooltip>
  )
};