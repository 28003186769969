import { useContext, useState } from 'react';
import { AppContext } from '../../../App';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../../api/api';

export default function WarningBar() {
  const {
    appState: {
      csvData,
      csvData: {
        results,
      },
    },
    appDispatch,
  } = useContext(AppContext);

  const [ isFieLoading, setLoading ] = useState(false);

  const sendToBeaconQc = async () => {
    if(!results?.sample_obj) {
      appDispatch({
        type: 'SET_ERROR',
        payload: 'There are no valid results in app',
      });
      return;
    };

    setLoading(true);

    const resp = await api.rApi.sendToBeaconQc(results.sample_obj)
      .catch(err => {
        console.log(err);
        appDispatch({
          type: 'SET_ERROR',
          payload: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    if (resp.status === 200) {
      appDispatch({
        type: 'SET_CSV_DATA',
        payload: {
          ...csvData,
          isQcResultEdited: false,
        }
      });
      appDispatch({
        type: 'SET_SUCCESS',
        payload: 'Results sent to Beacon QC'
      });
    }
  };

  return (
    <Box>
      <Paper sx={{
        borderRadius: '4px 4px 0 0',
        padding: '24px  0 24px',
      }}>
        <Button
            color="success"
            variant="contained"
            disableElevation
            size='large'
            sx={{
              marginRight: 1,
              marginLeft: 1,
            }}
            endIcon={isFieLoading ?
              <CircularProgress
                color="inherit"
                size={20}
              /> :
              <SendIcon />
            }
            onClick={sendToBeaconQc}
          >
            Send Edited Results to Beacon QC
          </Button>
          <Typography
              variant="subtitle1"
              component="div"
              sx={{
                display: 'inline-block',
                marginLeft: 1,
                padding: '8.5px 14px',
              }}
            >
              QC results changed manually, send updated results to Beacon QC application.
            </Typography> 
      </Paper>
    </Box>
  );
};
