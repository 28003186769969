import trimString from "./trimString";
export default function createCsvFile(csvHeadersObj: Record<string, any> | null, titlesObjArr: Record<string, any>[], tableDataArr: Record<string, any>[]): string {
  const titlesString = titlesObjArr.map(titleObj => titleObj.header).join(',');

  let csvHeaderRows: string[] = [];

  if(csvHeadersObj) {
    const csvHeaderValues = Object.entries(csvHeadersObj);

    csvHeaderRows = csvHeaderValues.map(headerValues => {
      headerValues[0] = headerValues[0].concat(':');
      headerValues[1] = (headerValues[1] && trimString(headerValues[1])) || 'N/A';
      headerValues.unshift('#');

      return headerValues.join(' ');
    });
  };

  const regex = /(,)+/g;
  const csvDataTextRows = tableDataArr.map((rowObj: Record<string, any>): string => {
    const csvDataRowStringsArr = titlesObjArr.reduce((acc: any[], titleObj: Record<string, any>): any[] => {
      let singleValue = rowObj[titleObj.accessorKey];
      const match = regex.exec(singleValue);

      if(match) {
        singleValue = `"${singleValue}"`;
      };

      acc.push(singleValue);

      return acc;
    }, []);

    return csvDataRowStringsArr.join(',');
  });

  const csvDataWithTitlesArr = [...csvHeaderRows, ...[titlesString], ...csvDataTextRows];

  return csvDataWithTitlesArr.join('\n');
};