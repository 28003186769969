import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TGenericMessageDialog } from './TGenericMessageDialog'

export default function GenericMessageDialog(props: TGenericMessageDialog) {
  const {
    handleMessageDialogOpen,
    genericMessage,
  } = props;

  return (
    <>
      <DialogTitle>
        Information
        <IconButton
          aria-label="close"
          onClick={handleMessageDialogOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {
              m: '16px 0',
            }
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant='body1'
            align='left'
            sx={{
              minWidth: '100%',
              paddingBottom: 5,
            }}
          >
            {genericMessage}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleMessageDialogOpen}
        >
          Ok
        </Button>
      </DialogActions>
    </>
  );
};