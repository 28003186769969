import LOCALSTORAGEKEYS from "../constants/localStorageKeys";
import { UserDataModel } from "../models/UserDataModel";

const { localStorage } = window;
const {
  BEACON_TOKEN,
  USER_DATA,
  CUSTOM_INSTRUMENT_SN,
} = LOCALSTORAGEKEYS;

const localStorageHelper = {
  getSaveToken(token?: string): string | undefined {
    if(!token) {
      return localStorage[BEACON_TOKEN];
    };
  
    localStorage[BEACON_TOKEN] = token;
  },
  getSaveUserData(userData?: UserDataModel | undefined): UserDataModel | undefined {
    if(!userData) {
      return localStorage[USER_DATA] ? JSON.parse(localStorage[USER_DATA]).userData : undefined;
    };

    localStorage[USER_DATA] = JSON.stringify({
      userData,
      iat: Date.now(),
    });
  },
  removeUserData(): void {
    if(localStorage[USER_DATA]) {
      delete localStorage[USER_DATA];
    };
  },
  getSaveCustomSerialNumber(instrumentSN?: string | undefined): string | undefined {
    if(!instrumentSN) {
      return localStorage[CUSTOM_INSTRUMENT_SN] ? localStorage[CUSTOM_INSTRUMENT_SN] : undefined;
    }

    localStorage[CUSTOM_INSTRUMENT_SN] = instrumentSN;
  },
  removeCustomSerialNumber(): boolean {
    if(localStorage[CUSTOM_INSTRUMENT_SN]){
      delete localStorage[CUSTOM_INSTRUMENT_SN];
      return true;
    } else {
      return false;
    }
  },
};

export default localStorageHelper;