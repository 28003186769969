import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../App';
import MaterialReactTable, {
  MaterialReactTableProps,
} from 'material-react-table';
import Dialog from '@mui/material/Dialog';
import HistoryDialog from './HistoryDialog';
import ApproveDialog from './ApproveDialog';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Edit from '@mui/icons-material/Edit';
import CheckBoxRounded from '@mui/icons-material/CheckBoxOutlined';
import History from '@mui/icons-material/History';
import { HandleHistoryModal, HandleApproveModal } from '../../../../models/HandleModalsModels';
import api from '../../../../api/api';
import { getEditsHistory } from '../../../../utils/firebaseHelper';
import createTableHeaders from '../../../../utils/createTableHeaders';
import createColumnsVisibilityConfig from '../../../../utils/createColumnsVisibilityConfig';
import TEST_TYPES from '../../../../constants/testTypes';
import COLUMNS_RESULT_VISIBLE_BY_DEFAULT from '../../../../constants/columnsResultVisibleByDefault';
import {
  RESULT_TABLE_DATA_KEYS,
  MANUAL_EDIT,
  EDIT_SELECT_OPTIONS,
} from '../../../../constants/tableKeys';

const {
  FINAL_RESULT,
  NOTES,
  QC_CONTROL,
  QC_RESULT,
  STATUS,
  TEST,
  WELLPOSITION,
} = RESULT_TABLE_DATA_KEYS;

export default function ResultsTable() {

  const {
    appState: {
      csvData,
      csvData: {
        csvHeadersObj,
        results,
        editsHistory,
        fileMd5,
      },
      fireStoreUploadId,
    },
    appDispatch,
  } = useContext(AppContext);

  const [historyState, handleHistoryState] = useState<HandleHistoryModal>({
    isOpen: false,
    currentWellMap: '',
    currentTarget: '',
  });

  const [approveState, handleApproveState] = useState<HandleApproveModal>({
    isOpen: false,
    currentRowIndex: 0,
  });

  const handleApproveDialogState = (newState: Record<string, any>) => {
    handleApproveState({
      ...approveState,
      ...newState,
    });
  };

  const handleHistoryDialogState = (newState: Record<string, any>) => {
    handleHistoryState({
      ...historyState,
      ...newState,
    });
  };

  let wrkData: any[] = [];
  let tableHeaders: any[] = [];

  const createEditsHistory = (editHistory: Record<string, any> | undefined): Record<string, any>[] | undefined => {
    if(!editHistory || !Object.keys(editHistory).length) return;

    const wellMaps = Object.keys(editHistory);
    const wrkDataArr: Record<string, any>[] = [];

    const {
      RESULT: RESULT_VALUES,
      QC_CONTROL: CONTROL_VALUES,
    } = EDIT_SELECT_OPTIONS;

    const resultValuesArr = Object.values(RESULT_VALUES)
      .map(value => value.toLowerCase());
    const qcValuesArr = Object.values(CONTROL_VALUES)
      .map(value => value.toLowerCase());

    // console.log('!!!VALUES', {
    //   resultValuesArr,
    //   qcValuesArr,
    // });

    wellMaps.forEach((wellMap: string) => {
      // Finding all targets as an array = ['FluA', 'FluB'];
      const targets: string[] = Object.keys(editHistory[wellMap].targets);

      targets.forEach((target: string) => {
        let resultKey;

        if(resultValuesArr.includes(editHistory[wellMap].targets[target].toLowerCase())) {
          resultKey = FINAL_RESULT;
        } else if(qcValuesArr.includes(editHistory[wellMap].targets[target].toLowerCase())) {
          resultKey = QC_RESULT;
        } else {
          // For a future cases
          resultKey = FINAL_RESULT;
        };

        const wrkDataObject = {
          [WELLPOSITION]: wellMap,
          [TEST]: target,
          [NOTES]: editHistory[wellMap].notes[target],
          // Need to implement dynamic key definition FINAL_RESULT / QC_RESULT
          // Depending on VALUE 'Detected' / 'Not Detected' || 'PASS' / 'FAIL'
          [resultKey]: editHistory[wellMap].targets[target],
          // [RESULT]: editHistory[wellMap].targets[target].toLowerCase().replace(' ', '_'),
          [STATUS]: MANUAL_EDIT,
        };

        wrkDataArr.push(wrkDataObject);
      });
    })

    return wrkDataArr;
  };

  if(results?.sample_obj) {
    tableHeaders = createTableHeaders(results.sample_obj.results[1]);
    // console.log('!!!TABLE_HEADERS', tableHeaders);

    const data = [...results.sample_obj.results];
    let editedWrkData: Record<string, any>[] | undefined;

    const editsHistoryArr = createEditsHistory(editsHistory?.finalEdits);
    // console.log('!!!testHistoryArr!!!', editsHistoryArr);

    if(editsHistoryArr) {
      editedWrkData = data.map(dataObj => {
        let finalObj = dataObj;

        editsHistoryArr.forEach(editedObj => { 
          if (dataObj.wellposition === editedObj.wellposition && dataObj.test === editedObj.test) {
            finalObj = {
              ...dataObj,
              ...editedObj,
            };
          }
        });

        return finalObj;
      });
    };
    // console.log('!!!editedWrkData!!!', editedWrkData);

    wrkData = editedWrkData ? editedWrkData : data;

    const {
      RESULT: {
        DETECTED,
        NOT_DETECTED,
      },
      QC_CONTROL: {
        PASS,
        FAIL,
      },
    } = EDIT_SELECT_OPTIONS;

    const resultSelectOptions = [
      DETECTED,
      NOT_DETECTED,
    ];

    const controlSelectOptions = [
      PASS,
      FAIL,
    ];

    tableHeaders.forEach(headerObj => {
      if(headerObj.accessorKey.toLowerCase() !== FINAL_RESULT &&
        headerObj.accessorKey.toLowerCase() !== QC_RESULT &&
        headerObj.accessorKey.toLowerCase() !== NOTES
      ) {
        headerObj.enableEditing = false;
      }
       else if(
          headerObj.accessorKey.toLowerCase() === FINAL_RESULT ||
          headerObj.accessorKey.toLowerCase() === QC_RESULT
        ) {
          headerObj.muiTableBodyCellEditTextFieldProps = ({cell, row}: {cell: any; row: any}) => {
          
          // console.log('!!!ROW VALUE!!!', row.original[TYPE]); // result || control

          // We need to do this in order to have unique set of options for each cell
          // Otherwise, we will push more and more options to defaultSelectOptions array
          // from each new cell which we edit (Array will be in closure)
          // const selectOptions = [...defaultSelectOptions];
          
          let selectOptions: string[] = [];
          let isFieldDisabled = false;

          if(
            headerObj.accessorKey.toLowerCase() === FINAL_RESULT &&
            !row.original[QC_CONTROL]
          ) {
            selectOptions = [...resultSelectOptions];
          } else if(
            headerObj.accessorKey.toLowerCase() === FINAL_RESULT &&
            !!row.original[QC_CONTROL]
          ) {
            isFieldDisabled = true;
          } else if(
            headerObj.accessorKey.toLowerCase() === QC_RESULT &&
            !!row.original[QC_CONTROL]
          ) {
            selectOptions = [...controlSelectOptions];
          } else if(
            headerObj.accessorKey.toLowerCase() === QC_RESULT &&
            !row.original[QC_CONTROL]
          ) {
            isFieldDisabled = true;
          };

          // console.log('!!!SELECT_OPTIONS!!!', selectOptions);

          // switch (row.original[TYPE]) {
          //   case RESULT: selectOptions = [...resultSelectOptions];
          //     break;
          //   case CONTROL: selectOptions = [...controlSelectOptions];
          //     break;
          //   default: selectOptions = [...resultSelectOptions, ...controlSelectOptions];
          // };

          const srcSelectValue = cell.getValue();
          // console.log('!!!CELL VALUE!!!', srcSelectValue);

          const isSrcValueInDefaultOptions = selectOptions.some(option => (
            option.toLowerCase() === srcSelectValue.toLowerCase()
          ));

          if(!isSrcValueInDefaultOptions) {
            selectOptions.unshift(srcSelectValue);
          }

          return {
            select: !isFieldDisabled, //change to select for a dropdown
            disabled: isFieldDisabled,
            children: !Boolean(isFieldDisabled) && selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              )),
          }
        };
      };
    });
  };

  // const memoWrkData = useMemo(() => wrkData, [wrkData]);

  // console.log('!!!tableHeaders!!!', tableHeaders);

  const isToxData = (csvHeadersObj.sampleType.toLocaleLowerCase() === TEST_TYPES.CONCENTRATION);

  let visibilityConfig;
  if(!isToxData) {
    visibilityConfig = {
      columnVisibility: createColumnsVisibilityConfig(tableHeaders, COLUMNS_RESULT_VISIBLE_BY_DEFAULT)
    };
  };

  const handleSaveRowEdits: MaterialReactTableProps<any>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {

      // const {
      //   CONTROL,
      // } = RESULT_TYPES;

      const srcData = {...wrkData[row.index]};

      const isQcResult = !!srcData[QC_CONTROL];

      const originalResult = isQcResult ? srcData[QC_RESULT] : srcData[FINAL_RESULT];
      // const originalResult = srcData[FINAL_RESULT];
      const wellPosition = values[WELLPOSITION]; // Names from config
      const target = values[TEST]; // Names from config
      const result = isQcResult ? values[QC_RESULT] : values[FINAL_RESULT]; // Names from config
      const notes = values[NOTES];
      // const resultType = values[TYPE];

      // console.log('!!_VALIES_!!', {wellPosition, result});
    
      await api.firebase.saveEdit({
        uploadId: fireStoreUploadId,
        originalResult,
        result,
        target,
        wellMap: wellPosition,
        notes: notes || '',
      })
        .catch(err => {
          console.log(err);
          // exitEditingMode(); ??
        });
      
      const updatedEditsHistory = await getEditsHistory(fileMd5)
        .catch(err => {
          console.log(err);
        });

      // console.log('!!EDITS HISTORY', editsHistory);
      
      values[STATUS] = MANUAL_EDIT;
      // values[RESULT] = values[FINAL_RESULT].toLowerCase().replace(' ', '_');
      wrkData[row.index] = values;

      const editedResults = {
        ...results,
      };

      editedResults.sample_obj!.results = [...wrkData];

      appDispatch({
        type: 'SET_CSV_DATA',
        payload: {
          ...csvData,
          ...editedResults,
          isQcResultEdited: isQcResult,
          editsHistory: updatedEditsHistory?.data ? updatedEditsHistory.data : editsHistory,
        }
      });

      // console.log('Edited', [...wrkData]);
      exitEditingMode(); //required to exit editing mode and close modal
    };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={historyState.isOpen || approveState.isOpen}
      >
        {Boolean(historyState.isOpen) && <HistoryDialog
          handleDialogOpen={handleHistoryDialogState}
          currentWellMap={historyState.currentWellMap}
          currentTarget={historyState.currentTarget}
        />}
        {Boolean(approveState.isOpen) && <ApproveDialog
          handleDialogOpen={handleApproveDialogState}
          tableHeaders={tableHeaders}
          currentRow={[wrkData[approveState.currentRowIndex]]}
        />}
      </Dialog>
      <MaterialReactTable
        columns={tableHeaders}
        data={wrkData}
        editingMode="modal"
        enableEditing={!isToxData}
        onEditingRowSave={handleSaveRowEdits}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            backgroundColor: row.original[STATUS] === MANUAL_EDIT ? 'khaki' : 'transparent',
          }
        })}
        renderRowActions={({ row, table }) => Boolean(!isToxData && row.original['type'] === 'result') && (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {Boolean(row.original[STATUS] === MANUAL_EDIT) && <>
              <Tooltip arrow placement="right" title="View history">
                <IconButton onClick={() => handleHistoryDialogState({
                    isOpen: true,
                    currentWellMap: row.original[WELLPOSITION],
                    currentTarget: row.original[TEST],
                  })}>
                  <History />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Review changes">
                <IconButton onClick={() => {
                  handleApproveDialogState({
                    isOpen: true,
                    currentRowIndex: row.index,
                  })
                }}>
                  <CheckBoxRounded />
                </IconButton>
              </Tooltip>
            </>}
          </Box>)
        }
        enableColumnOrdering={false}
        enableGlobalFilter={false}
        initialState={{
          ...visibilityConfig,
          ...{
            density: 'compact',
            pagination: {pageSize: 50, pageIndex: 0},
          }
        }} //hide columns by default
      />
    </>
  );
};
