import { 
  httpClientBeacon,
  httpClientRApi,
  httpClientFirebase,
  httpClientZendesk,
  httpClientWebhook,
} from "./httpClient/httpClient"
import { login, getUserData } from './beaconLogin/beaconLogin';
import rApi from "./rApi/rApi";
import firebase from "./firebase/firebase";
import assay from "./rApi/rApiAssay";
import formatData from "./rApi/rApiFormat";
import results from "./rApi/rApiResults";
import sendSrcCsv from "./rApi/rApiSendSrcCsv";
import zendesk from "./zendesk/zendesk";
import webhooks from "./webhooks/webhooks";
import URLS from '../constants/urls';

const {
  BEACON_LIS,
  R_API,
} = URLS;

const api = {
  beaconLogin: login(httpClientBeacon, BEACON_LIS.AUTH_URL),
  beaconGetUserData: getUserData(httpClientBeacon, BEACON_LIS.GET_USER_DATA),
  rApi: rApi(httpClientRApi),
  rApiAssay: assay(httpClientRApi, R_API.ASSAY),
  rApiFormatData: formatData(httpClientRApi, R_API.FORMAT),
  rApiResults: results(httpClientRApi, R_API.RESULTS),
  rApiSendSrcScv: sendSrcCsv(httpClientRApi, R_API.SEND_SRC_FILE),
  firebase: firebase(httpClientFirebase),
  zendesk: zendesk(httpClientZendesk),
  webhook: webhooks(httpClientWebhook),
};

export default api;