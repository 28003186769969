import { AxiosInstance } from 'axios';

export const login = (httpClient: AxiosInstance, authUrl: string) => ({
  login({ userName, userPassword }: { userName: string; userPassword: string }): Promise<any> {

    return httpClient.post(`/${authUrl}`, {
      username: userName,
      password: userPassword,
    });
  }
});

export const getUserData = (httpClient: AxiosInstance, userDataUrl: string) => ({
  getUserData(authToken?: string): Promise<any> {

    return httpClient.get(`/${userDataUrl}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      }
    });
  }
});

export default login;