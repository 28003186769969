import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import Typography from '@mui/material/Typography';
import { THelpDialog } from './THelpDialog';
import api from '../../api/api';
import { AppContext } from '../../App';

interface TextInputs {
  subject: string;
  comments: string;
};

export default function HelpDialog(props: THelpDialog) {
  const { handleHelpClick } = props;
  const {
    appState: {
      csvData: { csvFileName },
      userData,
    },
    appDispatch,
  } = useContext(AppContext);


  const userName = userData?.username;
  const userEmail = userData?.email;

  const [inputValues, setInputValues] = useState<TextInputs>({
    subject: '',
    comments: '',
  });

  const [isLoading, setLoading] = useState(false);

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleZendeskRequest = async () => {
    if(!userName) return;

    const messageBody = csvFileName ? (
      `File: ${csvFileName} Message Text: ${inputValues.comments}`
    ) : inputValues.comments;

    const ticketBody = {
      request: {
        requester: {
          name: userName,
          email: userEmail || 'test.user@pathlight.com',      
        },
        subject: inputValues.subject,
        comment: {
          body: messageBody,
        },
        custom_fields: [
          {
              id: 5179377177101,
              value: 'web_application'
          },
          {
              id: 10968332875021,
              value: 'software_support'
          },
          {
              id: 14662666360589,
              value: 'pathlight_uploader'
          }
      ]
      }
    };

    setLoading(true);

    const req = await api.zendesk.createRequest(ticketBody)
      .catch(err => {
        console.log(err);
        appDispatch({
          type: 'SET_ERROR',
          payload: err.message,
        });
      })
      .finally(() => setLoading(false));
    
    if(req) {
      setConfirmOpen(true);
      // appDispatch({
      //   type: 'SET_SUCCESS',
      //   payload: 'Your information was sent',
      // });
    };
  };

  return (
    <>
      <DialogTitle>
        Need help?
        {/* {Boolean(isConfirmOpen) ? 'Thank you!' : 'Need help?'} */}
        <IconButton
          aria-label="close"
          onClick={handleHelpClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isConfirmOpen ? 
        <DialogContent
          dividers
          sx={{
            textAlign: 'center',
          }}
        >
          <CheckCircleIcon
            color="success"
            sx={{ width: 150, height: 150 }}
          />
          <Typography
            variant='h5'
            align='center'
            sx={{
              minWidth: '100%',
              paddingBottom: 5,
            }}
          >
            Your request has been sent
          </Typography>
          <Button
            variant='contained'
            color='success'
            sx={{width: '100%'}}
            onClick={handleHelpClick}
          >
            CLOSE
          </Button>
        </DialogContent> :
        <>
          <DialogContent dividers>
            <Typography gutterBottom>
              User Name: {userName}
            </Typography>
            <Typography gutterBottom>
              Email: {userEmail || 'N/A'}
            </Typography>
            <Typography gutterBottom>
              {`File name: ${csvFileName}`}
            </Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {
                  m: '16px 0',
                }
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                disabled={isLoading}
                name="subject"
                label="Subject"
                fullWidth
                value={inputValues.subject}
                onChange={handleTextInput}
              />
              <TextField
                disabled={isLoading}
                name="comments"
                label="Comments"
                multiline
                rows={4}
                fullWidth
                value={inputValues.comments}
                onChange={handleTextInput}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={handleHelpClick}
              >
              Cancel
            </Button>
            <Button
              disabled={!(inputValues.subject && inputValues.comments) || isLoading}
              // disabled
              onClick={handleZendeskRequest}
            >
              Send Request
            </Button>
          </DialogActions>
        </>}
    </>
  );
};