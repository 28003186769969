import { useState, useContext } from 'react';
import { AppContext } from '../../App';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TabPanel from './TabPanel';
import WellMap from './DataScreens/WellMap/WellMap';
import MReactTable from './DataScreens/MReactTable/MReactTable';
import ResultsTable from './DataScreens/Results/ResultsTable';
import CutoffsTable from './DataScreens/CutoffsTable/CutoffsTable';
import SampleInfo from './DataScreens/SampleInfo/SampleInfo';
import EditsTable from './DataScreens/EditsTable/EditsTable';
import WarningBar from './WarningBar/WarningBar';
import Chip from '@mui/material/Chip';
import TEST_TYPES from '../../constants/testTypes';

function combineProps(index: number) {
  return {
    id: `pathlight-tab-${index}`,
    'aria-controls': `pathlight-tabpanel-${index}`,
  };
}

export default function DataBlock() {
  const {
    appState: {
      csvData: { 
        csvHeadersObj,
        tableDataArr,
        results,
        editsHistory,
        isQcResultEdited,
        dataForApi,
      },
    },
  } = useContext(AppContext);

  const [tabIndex, setTabIndex] = useState(0);

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const availableData: Record<string, boolean> = {
    wellMap: !!results?.chart_obj,
    data: !!tableDataArr.length,
    results: !!results?.sample_obj?.results,
    cutoffs: !!results?.cutoffs,
    sampleInfo: !!csvHeadersObj,
    editsHistory: !!editsHistory?.editsHistory.length,
  };

  const components = [
    {
      name: 'Well Map', // csvData.results.chart_obj
      accessorName: 'wellMap',
      component: WellMap,
      id: '01',
    },
    {
      name: 'Data', // csvData.tableDataArr
      accessorName: 'data',
      component: MReactTable,
      id: '02',
    },
    {
      name: 'Results', // csvData.results.sample_obj.results
      accessorName: 'results',
      component: ResultsTable,
      id: '03',
    },
    {
      name: 'Cutoffs', // csvData.results.cutoffs
      accessorName: 'cutoffs',
      component: CutoffsTable,
      id: '04',
    },
    {
      name: 'Sample Info', // csvData.csvHeadersObj
      accessorName: 'sampleInfo',
      component: SampleInfo,
      id: '05',
    },
    {
      name: 'Edits History',
      accessorName: 'editsHistory',
      component: EditsTable,
      color: 'khaki',
      id: '06',
    }
  ];

  // const renderConfig = !isConcentrationType ? components : components
  //   .filter(compObj => (compObj.name !== 'Well Map' && compObj.name !=='Cutoffs'));

  const renderConfig = components.filter(compObj => availableData[compObj.accessorName]);

  return (
    <Box>
      <Paper sx={{ borderRadius: '4px 4px 0 0' }}>
        <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
          position: 'relative',
        }}>
          <Tabs
            value={tabIndex}
            onChange={changeTab}
            aria-label="pathlight file uploader navigation"
          >
            {renderConfig.map((compObj, index) => (
              <Tab
                label={compObj.name}
                {...combineProps(index)}
                key={compObj.id}
                sx={{
                  backgroundColor: compObj.color ? compObj.color : 'transparent',
                }}
              />)
            )}
          </Tabs>
          {Boolean(dataForApi?.sample_info?.test_type === TEST_TYPES.CONCENTRATION) && <Chip
            label={dataForApi?.sample_info?.qc_run ? 'QC Results' : 'Results'}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />}
        </Box>
        {Boolean(isQcResultEdited) && <WarningBar />}
        {renderConfig.map((compObj, index) => (
          <TabPanel value={tabIndex} index={index} key={compObj.id}>
            <compObj.component />
          </TabPanel>
        ))}
      </Paper>
    </Box>
  );
}