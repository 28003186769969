const COLUMNS_RESULT_VISIBLE_BY_DEFAULT = [
  'well',
  'wellposition',
  'sample',
  'test',
  'value',
  'type',
  'result',
  'status',
  'final_result',
  'notes',
];

export default COLUMNS_RESULT_VISIBLE_BY_DEFAULT;