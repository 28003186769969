import React, { useContext } from 'react';
import { AppContext } from '../../App';
import { Navigate } from "react-router-dom";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const {
    appState: {
      userData,
    },
  } = useContext(AppContext);

  let userEmail = userData?.email || 'N/A';
  if(userData?.username === 'sandbox_api') {
    userEmail = 'na@lighthouselabservices.com';
  }

  if (!userData?.username || !userEmail.includes('@lighthouselabservices.com')) {
    return <Navigate to="/" />;
  }

  return children;
}