import Box from '@mui/material/Box';
import ChartContainer from "./ChartsContainer";
import ChartRow from "./ChartsRow";
import ChartItem from './ChartItem';

interface TWellDiagram {
  srcData: Record<string, any>;
  chartTitle?: string;
};

export default function WellDiagram(props: TWellDiagram) {

  const { srcData, chartTitle } = props;
  const keysArr = Object.keys(srcData);

  const length = Object.values(srcData)
    .reduce((acum: number, item: any) => acum >= item.length ? acum : item.length, 0) + 1;

  const myArr = Array.from(Array(length).keys());

  const XAxis = () => (
    <Box sx={{
      display: 'flex',
      height: 30,
      width: '100%'
    }}>
      {myArr.map((item) => (
        <ChartItem
          key={item}
          value={item === 0 ? '' : (item%5 ? '' : item.toString())}
          isTitleItem={item === 0}
          color="#fff"
        />)
        )}
    </Box>
  );

  return (
    <ChartContainer chartTitle={chartTitle}>
      {Boolean(keysArr.length) && keysArr.map((key, index) => (
        <ChartRow
          key={index}
          rowLetter={key}
          rowData={srcData[key]}
        />)
      )}
      <XAxis />
    </ChartContainer>
  )
};