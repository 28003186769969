import { AxiosInstance } from 'axios';


const sendSrcCsv = (httpClient: AxiosInstance, saveSrcCsvUrl: string) => ({
  sendSrcCsv(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${saveSrcCsvUrl}`, srcJson);
  }
});

export default sendSrcCsv;