import React, { useState, useContext } from 'react';
import { AppContext } from '../../App';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../api/api';
import localStorageHelper from '../../utils/localStorageHelper';
import { TLoginDialog } from './TLoginDialog';

interface textInputs {
  userName: string;
  userPassword: string;
};

export default function LoginDialog(props: TLoginDialog) {

  const { handleLoginOpen } = props;

  const { appDispatch } = useContext(AppContext);

  const [inputValues, setInputValues] = useState<textInputs>({
    // userName: 'sandbox_api',
    // userPassword: 'Lis@123!',
    userName: '',
    userPassword: '',
  });

  const [isLoading, setLoading] = useState(false);

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (): Promise<any> => {
    setLoading(true);
    const authData = await api.beaconLogin.login(inputValues);
    // console.log('LoginRes', authData?.data);

    const userData = await api.beaconGetUserData.getUserData(authData?.data?.access_token);
    // console.log('Fetched User Data Res', userData?.data);

    if(authData?.data && userData?.data) {
      localStorageHelper.getSaveUserData({
        ...authData.data,
        ...userData.data,
      });

      appDispatch({
        type: 'SET_USER_DATA',
        payload: {
          ...authData.data,
          ...userData.data,
        },
      });
      handleLoginOpen();
    }
  };

  const handleSubmit = () => {
    handleLogin()
      .catch(err => {
        const errTxt = err?.message
        console.log('errTxt', errTxt);
        appDispatch({
          type: 'SET_ERROR',
          payload: errTxt,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <DialogTitle>
        Please login with Beacon LIS credentials
        <IconButton
          aria-label="close"
          disabled={isLoading}
          onClick={handleLoginOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {
              m: '16px 0',
            }
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            disabled={isLoading}
            name="userName"
            label="User Name"
            type="email"
            fullWidth
            onChange={handleTextInput}
            value={inputValues.userName}
          />
          <TextField
            disabled={isLoading}
            name="userPassword"
            label="Password"
            type="password"
            fullWidth
            onChange={handleTextInput}
            value={inputValues.userPassword}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleLoginOpen}>Cancel</Button>
        <Button disabled={isLoading} onClick={handleSubmit}>Login</Button>
      </DialogActions>
    </>
  );
};