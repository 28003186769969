import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Footer() {
    return (
      <div>
        <Paper
          sx={{ borderRadius: '0 0 4px 4px' }}
        >
          <Box sx={{
            p: 2,
            display: 'flex',
          }}>
            <Typography variant="body2" sx={{flexGrow: 1}}>
              &#169; Lighthouse LIS Services 2023
            </Typography>
          </Box>
        </Paper>
      </div>
    );
  }