import Box from '@mui/material/Box';
import ChartItem from './ChartItem';

interface TChartRow{
  rowLetter: string;
  rowData: Record<string, any>[];
};

export default function ChartRow(props: TChartRow) {
  const {rowData, rowLetter} = props;
  return (
    <Box sx={{
      display: 'flex',
      height: 30,
      width: '100%'
    }}>
      <ChartItem
          value={rowLetter}
          color="#fff"
          isTitleItem
        />
      {rowData.map((rowItem, index) => (
        <ChartItem
          key={rowItem.value + index}
          value={rowItem.value}
          color={rowItem.clr}
          tooltip={rowItem.tooltip}
        />
      ))}
    </Box>
  )
};