import { AxiosInstance } from 'axios';


const formatData = (httpClient: AxiosInstance, formatDataUrl: string) => ({
  formatData(srcJson: {[key:string]: any}): Promise<any> {

    return httpClient.post(`/${formatDataUrl}`, srcJson);
  }
});

export default formatData;