import { useContext } from 'react';
import { AppContext } from '../../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DragAndDropArea from '../DropZone/DragAndDropArea';
import csvFileReader from '../../utils/csvFileReader';
import processCsv from '../../utils/processCsv';
import { CsvFileData } from '../../models/CsvFileData';
import api from '../../api/api';
import createWebhookData from '../../utils/createWebhookData';
import checkLabName from '../../utils/checkLabName';
import trimString from '../../utils/trimString';
import b64EncodeUnicode from '../../utils/b64EncodeUnicode';
import createReactMaterialTableStructure from '../../utils/createReactMaterialTableData';
import sendSrcCsvToBucket from '../../utils/sendSrcCsvToBucket';
import {saveUploadInfoToFirestore, getEditsHistory} from '../../utils/firebaseHelper';
// import detectQcRun from '../../utils/detectQcRun';
import detectMixedResults from '../../utils/detectMixedResults';
import createWebhookEventName from '../../utils/createWebHookEventName';
import MESSAGES from '../../constants/infoMessages';
import TEST_TYPES from '../../constants/testTypes';
import INSTRUMENT from '../../constants/instrumentDataKeys';

interface TDropZone {
  handleLoginOpen: Function;
  handleSNOpen: Function;
  setSNResolve: Function;
  setGetManSN: Function;
};

export default function DropZone(props: TDropZone) {

  const {
    appState: {
      isLoading,
      userData,
      csvData: {editsHistory: defaultEditsHistory},
      // manualInstrumentSN,
    },
    appDispatch
  } = useContext(AppContext);

  const isUserAuthorized = !!userData;

  const {
    handleLoginOpen,
    handleSNOpen,
    setSNResolve,
    setGetManSN,
  } = props;

  const handleFiles = (files: FileList): void => {
    csvFileReader(files, async ({csvFileName, csvFileBody}: CsvFileData) => {

      appDispatch({
        type: 'SET_LOADING',
        payload: true,
      });

      appDispatch({
        type: 'SET_MANUAL_INSTRUMENT_SN',
        payload: '',
      });

      // Send csvFileBody and csvFileName to AWS Bucker here
      const fileMd5Resp = await sendSrcCsvToBucket(csvFileName, csvFileBody)
        .catch(err => {
          console.log('Err', err.message);
          appDispatch({
            type: 'SET_ERROR',
            payload: err.message,
          })
        });

      const encodedToBase64Csv = b64EncodeUnicode(csvFileBody);

      // This is neccessery for extraction of all information from CSV Headers
      const localCsvFileData = processCsv(csvFileBody);

      const webhookDataInit = createWebhookData(
        createWebhookEventName('File converted'),
        'UNKNOWN',
        'UNKNOWN',
        'UNKNOWN',
        'UNKNOWN',
        'UNKNOWN',
        0,
        (userData?.email ? userData.email : 'N/A'),
        (new Date(Date.now()).toISOString()),
        200,
      );

      const instrumentTypeResp = await api.firebase.detectInstrument(encodedToBase64Csv)
        .catch(err => {
          console.log('Err', err.message);

          webhookDataInit.event = createWebhookEventName('File detected');
          webhookDataInit.message = err?.response?.data?.error || err.message;
          webhookDataInit.status_code = err.response.status;

          api.webhook.sendWebhook(webhookDataInit)
            .then()
            .catch(err => console.log(err));

          appDispatch({
            type: 'SET_ERROR',
            payload: err.message,
          });
        });

      // console.log('InstrumentTypeResp', instrumentTypeResp);

      let parcedCsvResp: null | Record<string, any> = null;

      if (instrumentTypeResp.data && instrumentTypeResp.data?.instrumentType && instrumentTypeResp.data?.instrumentType !== 'Unknown') {
        parcedCsvResp = await api.firebase.convertCsv(encodedToBase64Csv, instrumentTypeResp.data.instrumentType)
          .catch(err => {
            console.log('Err', err.message);

            webhookDataInit.event = createWebhookEventName('File converted');
            webhookDataInit.message = err?.response?.data?.error || err.message;
            webhookDataInit.status_code = err.response.status;

            api.webhook.sendWebhook(webhookDataInit)
              .then()
              .catch(err => console.log(err));

            appDispatch({
              type: 'SET_ERROR',
              payload: err.message,
            });
          });
      } else {
        // STOP PROCESSING FILE if instrument type unknown
        appDispatch({
          type: 'SET_LOADING',
          payload: false,
        });
         return appDispatch({
          type: 'SET_ERROR',
          payload: 'Cannot detect instrument type',
        });
      };

      // console.log('!!parcedCsvResp!!', parcedCsvResp);

      let testMReactData = null;

      if(parcedCsvResp?.data?.res_dt && parcedCsvResp?.data?.sample_info) {
        testMReactData = createReactMaterialTableStructure(parcedCsvResp?.data?.res_dt);
      } else {
        // STOP PROCESSING FILE if file cannot be converted
        appDispatch({
          type: 'SET_LOADING',
          payload: false,
        });

        return appDispatch({
          type: 'SET_ERROR',
          payload: 'Cannot convert CSV file',
        });
      }

      // console.log('!!!testMReactData!!!', testMReactData);

      // Need to Handle Err Parsing response from Firebase API
      if(!testMReactData) {

        appDispatch({
          type: 'SET_LOADING',
          payload: false,
        });

        return appDispatch({
          type: 'SET_ERROR',
          payload: 'Cannot convert instrument file',
        })
      };
      
      const {
        titlesObjArr,
        // tableDataArr,
      } = testMReactData as Record<string, any>;

      const localCsvHeadersObj = localCsvFileData ? localCsvFileData.csvHeadersObj : {};

      const csvHeadersObj = {
        ...localCsvHeadersObj,
        sampleType: parcedCsvResp?.data?.sample_info?.test_type,
        instrumentSN: parcedCsvResp?.data?.sample_info?.instrument_sn,
      };
      
      let instrumentSN = '';
      instrumentSN = parcedCsvResp?.data?.sample_info?.instrument_sn || csvHeadersObj[INSTRUMENT.SN_KEY.CONCENRATION];
      const instrumentType = parcedCsvResp?.data?.sample_info?.instrument || csvHeadersObj[INSTRUMENT.TYPE];

      // If there is no instrument SN in src file
      // Trying to get previosly saved SN from local storage
      // if(manualInstrumentSN) {
      //   instrumentSN = manualInstrumentSN;
      // };

      // If there is no SN both in File & Local Storage
      // Open manual instrument entry dialog here
      // Opening SN Dialog and stop the main flow
      // till the number will be provided
      if(!instrumentSN) {
        await new Promise((resolve) => {
          console.log('Delay In');
          setSNResolve(resolve);
          handleSNOpen();
        });
  
        // console.log('Delay Out');
        // console.log('!!Manual Instrument SN', setGetManSN());

        const customInstrumentSN = setGetManSN();

        if(customInstrumentSN){
          instrumentSN = customInstrumentSN;
          // localStorageHelper.getSaveCustomSerialNumber(customInstrumentSN);

          appDispatch({
            type: 'SET_MANUAL_INSTRUMENT_SN',
            payload: customInstrumentSN,
          });
        };
      };

      // If Instrument SN still not present - Stop file processing
      if(!instrumentSN) {
        appDispatch({
          type: 'SET_LOADING',
          payload: false,
        });

        return appDispatch({
          type: 'SET_ERROR',
          payload: 'Instrument Serial Number not found',
        });
      };

      const webhookData = createWebhookData(
        createWebhookEventName('File Loaded'),
        instrumentType,
        csvHeadersObj.sampleType.toLowerCase(),
        csvFileName,
        instrumentSN,
        // labName?.data?.lab_name || 'UNKNOWN',
        'UNKNOWN',
        0,
        (userData?.email ? userData.email : 'N/A'),
        (new Date(Date.now()).toISOString()),
        200,
      );
      
      const tableDataArrContext = testMReactData.tableDataArr;

      // webhookData.event = createWebhookEventName('File Loaded');
      webhookData.number_rows = tableDataArrContext.length;

      const labName = await api.firebase.getLabName(trimString(instrumentSN))
        .catch(err => {
          console.log(err);

          webhookData.event = createWebhookEventName('Lab Name requested');
          webhookData.message = err?.response?.data?.error || err.message;
          webhookData.status_code = err.response.status;

          api.webhook.sendWebhook(webhookData)
            .then()
            .catch(err => console.log(err));

          appDispatch({
            type: 'SET_ERROR',
            payload: err.message,
          });
        });

      if(checkLabName(labName?.data?.lab_name)) {
        appDispatch({
          type: 'SET_WARNING',
          payload: MESSAGES.LAB_UNKNOWN,
        });
      };

      webhookData.lab_name = labName?.data?.lab_name || 'UNKNOWN';

      let firebaseResp: Record<string, any> | undefined;
      let editsHistory;
      let fileMd5Hash = '';

      if(fileMd5Resp?.data) {
        const { data: fileMd5 } = fileMd5Resp;
        fileMd5Hash = fileMd5;

        firebaseResp = await saveUploadInfoToFirestore(
          fileMd5,
          csvFileName,
          userData?.email ? userData.email : 'N/A',
          instrumentSN,
          [{
            labName: labName?.data?.lab_name || 'UNKNOWN',
            labId: 0,
          }],
          'web',
        )
          .catch(err => {
            console.log(err);
          });
        
        editsHistory = await getEditsHistory(fileMd5)
          .catch(err => {
            console.log(err);
          });
      };

      // let isQcRun = false;
      const isQcRun = parcedCsvResp?.data?.sample_info?.qc_run;
      let isMixedresults = false;

      if(parcedCsvResp?.data?.sample_info?.test_type === TEST_TYPES.CONCENTRATION) {
        webhookData.qc_run = isQcRun;

        if(!isQcRun) {
          isMixedresults = detectMixedResults(tableDataArrContext);
        };
      } else if(csvHeadersObj.sampleType.toLowerCase() === TEST_TYPES.MOLECULAR) {
        delete webhookData.qc_run;
      };

      // Creating {res_dt: [{},..], sample_info: {}} Structure
      // will be available after /api/v1/convert call

      // We need to check the file for Mexed Results
      // And if so, we need to stop processing here by
      // Setting only dataArrContext to the app state
      // And prevent file data from sending to /results and /tobeaconqc endpoints

      let resultsTestData = null;
      
      if(!isMixedresults) {
        resultsTestData = {
          sample_info: {
            ...parcedCsvResp.data.sample_info,
            instrument_sn: instrumentSN,
            file_name: csvFileName,
            lab_name: labName?.data?.lab_name || 'UNKNOWN',
          },
          res_dt: parcedCsvResp.data.res_dt,
        };
     } else {
        appDispatch({
          type: 'SET_GENERIC_INFO_MESSAGE',
          payload: MESSAGES.MIXED_RESULTS_WARNING,
        });
     }

      // console.log('!!!resultsTestData', resultsTestData);

      const resultsFromApi = resultsTestData ?
        await api.rApi.getResults(resultsTestData)
          .catch(err => {
            console.log('Err', err.message);

            webhookData.message = err?.response?.data?.error || err.message;
            webhookData.status_code = err.response.status;

            api.webhook.sendWebhook(webhookData)
              .then()
              .catch(err => console.log(err));

            api.firebase.saveUploadInfo({
              ...webhookData,
              file_md5: fileMd5Hash,
              upload_id: firebaseResp?.data?.uploadId || '',
            })
              .then()
              .catch(err => console.log(err));

            if(err.response.status === 503) {
              appDispatch({
                type: 'SET_WARNING',
                payload: err?.response?.data?.error,
              })
            };

            appDispatch({
              type: 'SET_ERROR',
              payload: `${err?.response?.data?.error || err.message} Error code: ${err.response.status}`,
            })
          }) : null;

      if(resultsFromApi) {
        webhookData.status_code = resultsFromApi.status;          
        api.webhook.sendWebhook(webhookData)
          .then()
          .catch(err => console.log(err));

        api.firebase.saveUploadInfo({
          ...webhookData,
          file_md5: fileMd5Hash,
          upload_id: firebaseResp?.data?.uploadId || '',
          assay_id: resultsFromApi.data?.sample_obj?.assay_id || '',
        })
          .then()
          .catch(err => console.log(err));
        
        webhookData.event = createWebhookEventName('File Sent to QC');

        const sentToQCresp: Record<string, any> = await api.firebase.sendToQc({
          sample_obj: {
            ...resultsFromApi.data.sample_obj
          },
        })
          .catch(err => {
            console.log('Err', err.message);

            webhookData.message = err.message;
            webhookData.status_code = err.response.status;

            api.webhook.sendWebhook(webhookData)
              .then()
              .catch(err => console.log(err));

            api.firebase.saveUploadInfo({
              ...webhookData,
              file_md5: fileMd5Hash,
              upload_id: firebaseResp?.data?.uploadId || '',
              assay_id: resultsFromApi.data?.sample_obj?.assay_id || '',
            })
              .then()
              .catch(err => console.log(err));
    
            appDispatch({
              type: 'SET_ERROR',
              payload: err.message,
            });

          })
          .finally(() => appDispatch({
              type: 'SET_LOADING',
              payload: false,
            })
          );
    
        if(!sentToQCresp) {    
          webhookData.message = 'No response from FB API';
          webhookData.status_code = 0;

          api.webhook.sendWebhook(webhookData)
            .then()
            .catch(err => console.log(err));

          api.firebase.saveUploadInfo({
            ...webhookData,
            file_md5: fileMd5Hash,
            upload_id: firebaseResp?.data?.uploadId || '',
            assay_id: resultsFromApi.data?.sample_obj?.assay_id || '',
          })
            .then()
            .catch(err => console.log(err));

          appDispatch({
            type: 'SET_ERROR',
            payload: MESSAGES.QC_SEND_ERROR,
          })
        } else if(sentToQCresp.status !== 200 && sentToQCresp.status !== 202) {
          webhookData.message = sentToQCresp.data?.error;
          webhookData.status_code = sentToQCresp.status;

          api.webhook.sendWebhook(webhookData)
            .then()
            .catch(err => console.log(err));

          api.firebase.saveUploadInfo({
            ...webhookData,
            file_md5: fileMd5Hash,
            upload_id: firebaseResp?.data?.uploadId || '',
            assay_id: resultsFromApi.data?.sample_obj?.assay_id || '',
          })
            .then()
            .catch(err => console.log(err));

          appDispatch({
            type: 'SET_ERROR',
            payload: `${MESSAGES.QC_SEND_ERROR} ${sentToQCresp?.data?.error}`
          });
        } else {
          webhookData.message = sentToQCresp.data?.message;
          // We can hardcode 200 status here (As long as 202 goes to err in Slack bot)
          webhookData.status_code = 200;

          api.webhook.sendWebhook(webhookData)
            .then()
            .catch(err => console.log(err));

          api.firebase.saveUploadInfo({
            ...webhookData,
            file_md5: fileMd5Hash,
            upload_id: firebaseResp?.data?.uploadId || '',
            assay_id: resultsFromApi.data?.sample_obj?.assay_id || '',
          })
            .then()
            .catch(err => console.log(err));
        }
      };
      
      if(!resultsFromApi) {
        appDispatch({
          type: 'SET_LOADING',
          payload: false,
        });
      };

      appDispatch({
        type: 'SET_FIRESTORE_UPLOAD_ID',
        payload: firebaseResp?.data?.uploadId || '',
      });

      // console.log('!!results!!', {resultsFromApi, webhookData});

      appDispatch({
        type: 'SET_CSV_DATA',
        payload: {
          csvRawData: csvFileBody,
          csvFileName,
          csvHeadersObj,
          titlesObjArr: testMReactData?.titlesObjArr || titlesObjArr,
          tableDataArr: tableDataArrContext,
          dataForApi: resultsTestData,
          results: resultsFromApi ? resultsFromApi.data : resultsFromApi,
          editsHistory: editsHistory ? editsHistory.data : defaultEditsHistory,
          fileMd5: fileMd5Hash,
          isQcResultEdited: false,
        },
      });
    });
  };

  return (
    <Box>
      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3,
        minHeight: 500,
      }}>
        {isLoading ? <CircularProgress sx={{
          minWidth: 100,
          minHeight: 100,
        }} /> : (
          <>
          <DragAndDropArea
            handleFiles={handleFiles}
            handleLoginOpen={handleLoginOpen}
            isUserAuthorized={isUserAuthorized}
          />
          <Typography
              variant='h5'
              align='center'
              sx={{
                minWidth: '100%',
              }}
            >
              Drag 'n' drop .csv here, or click to select file
            </Typography>
          </>
          )
        }
      </Paper>
    </Box>
  );
}