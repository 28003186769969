// Helper for converting snake case titles from API to capitalized separate words

function toUpperCase(word: string): string {
 return word[0].toUpperCase().concat(word.slice(1))
};

export default function convertTableHeaderNames(srcTitle: string): string {
    const wordsArr = srcTitle.split('_');
    const transformedRes = Array.isArray(wordsArr) ? wordsArr.map(toUpperCase).join(' ') : toUpperCase(srcTitle);
  
    return transformedRes;
  };