import React from 'react';

export function inputFileHandler(event: React.ChangeEvent<HTMLInputElement>): FileList | undefined {
  if(!event.currentTarget.files) return;
  const acceptedFiles = event.currentTarget.files;
  return acceptedFiles;
};

export default function csvFileReader(acceptedFiles: FileList, callback: Function): void {
  const csvFile = acceptedFiles[0];
  const csvFileName = csvFile.name;

  const reader = new FileReader();
  reader.onload = function(e) {
      if (!e.target) return;
      const csvFileBody = e.target.result;
      // console.log('csvFileBody in csvFileReader', csvFileBody);
      if (!(typeof csvFileBody === 'string')) return;
      callback({
        csvFileName,
        csvFileBody,
      });
  }
  reader.readAsText(csvFile);
};