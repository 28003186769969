import { useContext } from 'react';
import { AppContext } from '../../../../App';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import trimString from '../../../../utils/trimString';

export default function SampleInfo() {
  
  const {
    appState: {
      csvData: {
        csvFileName,
        csvHeadersObj,
      }
    },
  } = useContext(AppContext);
  const titles = Object.keys(csvHeadersObj);

  return (
    <Box>
      <Typography gutterBottom variant='h5'>
        {`File name: ${csvFileName}`}
      </Typography>
      {titles.map(title => (
        <Box 
          key={title}
          sx={{
            paddingBottom: 1,
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {`${trimString(title)}: `}
          </Typography>
          <Typography variant="body1" component="span">
            {`${(csvHeadersObj[title] && trimString(csvHeadersObj[title])) || 'N/A'}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}