export const RESULT_TABLE_DATA_KEYS = {
  FINAL_RESULT: 'final_result',
  NOTES: 'notes',
  ORIGINAL_RESULT: 'originalResult',
  QC_CONTROL: 'qc_control',
  QC_RESULT: 'qc_result',
  RESULT: 'result',
  STATUS: 'status',
  TEST: 'test',
  TARGET: 'target',
  TYPE: 'type',
  USER: 'user',
  UPLOAD_TIME: 'uploadTime',
  WELLMAP: 'wellmap',
  WELLPOSITION: 'wellposition',
};

export const MANUAL_EDIT = 'Manual Edit';

export const TABLE_HEADER_TITLES = {
  FINAL_RESULT_HEADER: 'Final Result',
  QC_RESULT_HEADER: 'QC Result',
};

export const RESULT_TYPES = {
  RESULT: 'result',
  CONTROL: 'control',
};

export const EDIT_SELECT_OPTIONS = {
  RESULT: {
    DETECTED: 'Detected',
    NOT_DETECTED: 'Not Detected',
  },
  CONTROL: {
    CONTROL_DETECTED: 'Control Detected',
    CONTROL_NOT_DETECTED: 'Control Not Detected',
  },
  QC_CONTROL: {
    PASS: 'Pass',
    FAIL: 'Fail',
  },
};
      