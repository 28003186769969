import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import MaterialReactTable from 'material-react-table';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function Instruments() {
  const {
    appState: {
      instrumentsData: {
        instruments,
      },
    },
    appDispatch,
  } = useContext(AppContext);

  useEffect(() =>  {
    api.firebase.getInstruments()
      .then(resp => {
        if(resp.data) {
          appDispatch({
            type: 'SET_INSTRUMENTS_DATA',
            payload: {
              instruments: resp.data.instruments,
            }
          })
        }
      })
      .catch(err => {
        appDispatch({
          type: 'SET_DASHBOARD_DATA',
          payload: {
            currentUpload: null,
            currentUploadData: [],
          }
        });
        appDispatch({
          type: 'SET_ERROR',
          payload: err?.response?.data?.message || err.message,
        });
      });
  }, [appDispatch]);

  const navigate = useNavigate();

  const tableHeaders = [
    {
      header: 'Client Key',
      accessorKey: 'clientKey',
    },
    {
      header: 'Instrument Type',
      accessorKey: 'instrumentType',
    },
    {
      header: 'Instrument SN',
      accessorKey: 'instrumentSn',
    },
    {
      header: 'Hardware ID',
      accessorKey: 'hardwareId',
    },
    {
      header: 'Updated At',
      accessorKey: 'modifiedAt',
    },
  ];

  let wrkData: any[] = [];

  wrkData = useMemo(() => instruments?.length ? instruments.map((instrumentInfo: Record<string, any>) => {
    const {
      instrumentType,
      hardwareId,
      clientKey,
      web,
      instrumentSn,
      localIp,
      ssh,
      logs,
      createdAt,
      modifiedAt
    } = instrumentInfo;

    return {
      instrumentType,
      hardwareId,
      clientKey,
      web,
      instrumentSn,
      localIp,
      ssh,
      logs,
      createdAt: new Date(createdAt).toLocaleString(),
      modifiedAt: new Date(modifiedAt).toLocaleString(),
    }
  }) : [], [instruments]);

  return (
    <Box>
      <Paper sx={{ borderRadius: '4px 4px 0 0' }}>
        <MaterialReactTable
          columns={tableHeaders}
          data={wrkData}
          enableEditing={false}
          enableColumnOrdering={false}
          enableGlobalFilter={false}
          initialState={{
            ...{
              density: 'compact',
              pagination: {pageSize: 50, pageIndex: 0},
            }
          }}
          muiTableBodyRowProps={({row}) => ({
            onClick: () => {
              const {hardwareId} = instruments[row.index];
              navigate(`${hardwareId}`);
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
              '&:hover': {
                backgroundColor: 'lightblue !important'
              }
            },
          })}
        />
      </Paper>
    </Box>
  );
}