import axios from 'axios';
// import authInterceptor from './authInterceptor';
import URLS from '../../constants/urls';


const {
  BEACON_LIS,
  R_API,
  FIREBASE,
  ZENDESK,
  WEBHOOK_URL,
} = URLS;

export const httpClientBeacon = axios.create({
  baseURL: BEACON_LIS.BASE_URL
});

export const httpClientRApi = axios.create({
  baseURL: R_API.BASE_URL
});

export const httpClientFirebase = axios.create({
  baseURL: FIREBASE.FUNCTIONS_BASE_URL
});

export const httpClientZendesk = axios.create({
  baseURL: ZENDESK.BASE_URL
});

export const httpClientWebhook = axios.create({
  baseURL: WEBHOOK_URL
});

// httpClientBeacon.interceptors.request.use(authInterceptor);