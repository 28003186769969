import REQ_KEYS from "../constants/requiredCSVTableKeys";

const detectMixedResults = (tableDataArr: Record<string, any>[]): boolean => {
  // We will be processing QC false files
  // We know, that there is no QC keyword in every row
  // Thus, We need to check values till the first QC keyword appearance

  const isMixedResults = tableDataArr.find(obj => {
    const valuesAsAString = Object.values(obj).join(' ').toLowerCase();

    if(valuesAsAString.includes(REQ_KEYS.QC_CONC.QC) &&
      (
        valuesAsAString.includes(REQ_KEYS.QC_CONC.HIGH) ||
        valuesAsAString.includes(REQ_KEYS.QC_CONC.LOW) ||
        valuesAsAString.includes(REQ_KEYS.QC_CONC.MID)
      )) {
        return true;
      } else {
        return false;
      }
  });

  return !!isMixedResults;
};

export default detectMixedResults;