const DISABLED_FOR_EDIT_COLS = [
  'Well',
  'Well Position',
  // 'Sample',
  // 'Target',
  'Reporter',
  // 'Amp Status',
  // 'Amp Score',
  // 'Cq',
  // 'Cq Confidence',
  'Threshold',
  'Baseline Start',
  'Baseline End',
];

const createReactMaterialTableStructure = (csvDataBodyArray: Record<string, any>[]): {
  titlesObjArr: Record<string, any>[];
  tableDataArr: Record<string, any>[];
} => {
  if(!(Array.isArray(csvDataBodyArray) && csvDataBodyArray.length)) {
    console.log('Wrong data strucure');

    return {
      titlesObjArr: [],
      tableDataArr: [],
    };
  };

  const columnTitles = csvDataBodyArray[0];
  // const dataRows = csvDataBodyArray.slice(1);

  // Formatting Titles according to Material-React-Table format
  // {
  //   header: 'First Name',
  //   accessorKey: 'firstname', 
  // },
  // {
  //   header: 'Age',
  //   accessorKey: 'age', 
  // },
  // { 
  //  header: 'Amp.Score', // !There is a Problem with a sucn format
  //  accessorKey: amp.score,
  // }
  //

  // New col titles will be 1st element of array: [{'header Name 1': 'value', 'header Name 2': 'value', ..}, ... ]
  const titlesObjArr = Object.keys(columnTitles).map((titleKey: string) => {
    const flatHeaderTitle = titleKey.split('.').join('').toLowerCase().trim();
      return {
        header: titleKey,
        accessorKey: flatHeaderTitle,
        enableEditing: !DISABLED_FOR_EDIT_COLS.includes(titleKey),
        // accessorFn: (row: {[key: string]:any}) => row[flatHeader],
        // id: flatHeader,
        // 
      };
  })
  
  
  // const titlesObjArr = columnTitles.map(header => {
  //   const flatHeader = header.split(' ').join('').toLowerCase().trim();
  //   return {
  //     header,
  //     accessorKey: flatHeader,
  //     enableEditing: !disabledCols.includes(header),
  //     // accessorFn: (row: {[key: string]:any}) => row[flatHeader],
  //     // id: flatHeader,
  //     // 
  //   };
  // });

  // Formatting table data rows according to Material-React-Table format
  // We have:
  // {
  //   "SampleName": "Half Cal",
  //   "Compound": "Heroin Metabolite (6MAM)",
  //   "Conc": "6 ng/ml"
  // }
  // And we need:
  // {
  //   samplename: 'Half Cal',
  //   compound: 'Heroin Metabolite (6MAM)',
  //   conc: '6 ng/ml',
  // }

  const tableDataArr = csvDataBodyArray.map(rowObj => {
    const eachObject = Object.keys(columnTitles).reduce((obj: Record<string, any>, title: string): Record<string, any> => {
        const flatTitle = title.split('.').join('').toLowerCase().trim();
        obj[flatTitle] = rowObj[title];
        return obj;
      }, {})
      
    return eachObject;
  });

  // const tableDataArr = dataRows.map( row => {

  //   const eachObject = columnTitles.reduce((obj: Record<string, any>, title: string, i): Record<string, any> => {
  //       const flatTitle = title.split(' ').join('').toLowerCase().trim();
  //       obj[flatTitle] = row[i].replaceAll('{}', ',');
  //       return obj;
  //     }, {})
      
  //   return eachObject;
  // });

  return {
    titlesObjArr,
    tableDataArr,
  }
};

export default createReactMaterialTableStructure;
