import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import MaterialReactTable from 'material-react-table';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Dashboard() {
  const {
    appState: {
      dashboardData: {
        uploads,
      }
    },
    appDispatch,
  } = useContext(AppContext);

  useEffect(() =>  {
    api.firebase.getUploads({
      limit: 100,
      order: 'desc',
    })
      .then(resp => {
        if(resp.data) {
          appDispatch({
            type: 'SET_DASHBOARD_DATA',
            payload: {
              uploads: resp.data,
            }
          })
        }
      })
      .catch(err => {
        appDispatch({
          type: 'SET_ERROR',
          payload: err?.response?.data?.message || err.message,
        });
      });
  }, [appDispatch]);

  const navigate = useNavigate();

  const tableHeaders = [
    {
      header: 'Upload Time',
      accessorKey: 'uploadTime',
    },
    {
      header: 'File Name',
      accessorKey: 'fileName',
    },
    {
      header: 'Instrument SN',
      accessorKey: 'instrumentSn',
    },
    {
      header: 'Lab Name',
      accessorKey: 'labName',
    },
    {
      header: 'User',
      accessorKey: 'user',
    },
    {
      header: 'Source',
      accessorKey: 'source',
    },
  ];

  let wrkData: any[] = [];

  wrkData = useMemo(() => uploads.length ? uploads.map((upload: Record<string, any>) => ({
      fileName: upload.fileName,
      user: upload.user,
      labName: upload?.labNames ? (upload.labNames[0] || 'N/A') : 'N/A',
      instrumentSn: upload.instrumentSn || 'N/A',
      uploadTime: new Date(upload.uploadTime).toLocaleString(),
      source: upload?.source || 'N/A',
    })
  ) : [], [uploads]);

  return (
    <Box>
      <Paper sx={{ borderRadius: '4px 4px 0 0' }}>
        <Typography
          variant='h6'
          sx={{
            padding: '16px 0 0 24px'
          }}
        >
          Dashboard
        </Typography>
        <MaterialReactTable
          columns={tableHeaders}
          data={wrkData}
          enableEditing={false}
          enableColumnOrdering={false}
          enableGlobalFilter={false}
          initialState={{
            ...{
              density: 'compact',
              pagination: {pageSize: 50, pageIndex: 0},
            }
          }}
          muiTableBodyRowProps={({row}) => ({
            onClick: () => {
              const {fileMd5, id} = uploads[row.index];
              console.log({
                fileMd5,
                id,
              });

              navigate(`${fileMd5}/${id}`);
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
              '&:hover': {
                backgroundColor: 'lightblue !important'
              }
            },
          })}
        />
      </Paper>
    </Box>
  );
}