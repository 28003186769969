import {
  useState,
  useEffect,
  useCallback,
  useReducer,
  createContext
} from 'react';
import { Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';
import AppHeader from './components/AppHeader/AppHeader';
import FilePicker from './components/FilePicker/FilePicker';
import DropZone from './components/DropZone/DropZone';
import DataBlock from './components/DataBlock/DataBlock';
import Footer from './components/Footer/Footer';
import RequireAuth from './components/RequireAuth/RequireAuth';
import Dashboard from './components/Dashboard/Dashboard';
import Instruments from './components/Instruments/Instruments';
import Instrument from './components/Instruments/Instrument';
import UploadInfo from './components/Dashboard/UploadInfo/UploadInfo';
import Dialog from '@mui/material/Dialog';
import HelpDialog from './components/HelpDialog/HelpDialog';
import LoginDialog from './components/LoginDialog/LoginDialog';
import SNDialog from './components/SNDialog/SNDialog';
import GenericMessageDialog from './components/GenericMessageDialog/GenericMessageDialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import localStorageHelper from './utils/localStorageHelper';
import { CsvDataContextModel } from './models/CsvDataContextModel';
import { DashboardDataModel } from './models/DashboardDataModel';
import { UserDataModel } from './models/UserDataModel';
import { InstrumentsDataModel } from './models/InstrumentsDataModel';

interface AppStateModel {
    csvData: CsvDataContextModel;
    userData: UserDataModel | null;
    dashboardData: DashboardDataModel,
    instrumentsData: InstrumentsDataModel,
    isLoading: boolean;
    fireStoreUploadId: string;
    configUpdateTime: string;
    error: string;
    success: string;
    warning: string;
    manualInstrumentSN: string;
    genericInfoMessage: string;
};

interface AppContextModel {
  appState: AppStateModel;
  appDispatch: React.Dispatch<any>;
};

type ACTIONTYPE =
  | { type: 'SET_CSV_DATA'; payload: CsvDataContextModel }
  | { type: 'SET_DASHBOARD_DATA'; payload: DashboardDataModel }
  | { type: 'SET_INSTRUMENTS_DATA'; payload: InstrumentsDataModel }
  | { type: 'SET_USER_DATA'; payload: UserDataModel }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_SUCCESS'; payload: string }
  | { type: 'SET_WARNING'; payload: string}
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_UPDATE_CONFIG_TIME'; payload: string }
  | { type: 'SET_FIRESTORE_UPLOAD_ID'; payload: string }
  | { type: 'SET_MANUAL_INSTRUMENT_SN'; payload: string }
  | { type: 'SET_GENERIC_INFO_MESSAGE'; payload: string }

const appInitialState: AppStateModel = {
  csvData: {
    csvRawData: '',
    csvFileName: 'No file selected',
    csvHeadersObj: {},
    titlesObjArr: [],
    tableDataArr: [],
    dataForApi: null,
    results: null,
    editsHistory: {
      finalEdits: {},
      editsHistory: [],
    },
    fileMd5: '',
    isQcResultEdited: false,
  },
  dashboardData: {
    uploads: [],
    currentUpload: null,
    currentUploadData: [],
  },
  instrumentsData: {
    instruments: [],
    currentInstrument: null,
    instrumentConfigData: null,
  },
  userData: null,
  isLoading: false,
  fireStoreUploadId: '',
  configUpdateTime: '',
  error: '',
  success: '',
  warning: '',
  manualInstrumentSN: '',
  genericInfoMessage: '',
};

const appReducer = (state: AppStateModel, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_CSV_DATA':
      return {
        ...state,
        csvData: action.payload,
      };
    case 'SET_DASHBOARD_DATA':
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          ...action.payload
        },
      };
    case 'SET_INSTRUMENTS_DATA':
      return {
        ...state,
        instrumentsData: {
          ...state.instrumentsData,
          ...action.payload
        },
      };
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: action.payload,
      };
    case 'SET_UPDATE_CONFIG_TIME':
      return {
        ...state,
        configUpdateTime: action.payload,
      };
    case 'SET_FIRESTORE_UPLOAD_ID':
      return {
        ...state,
        fireStoreUploadId: action.payload,
      };
    case 'SET_MANUAL_INSTRUMENT_SN':
      return {
        ...state,
        manualInstrumentSN: action.payload,
      };
    case 'SET_GENERIC_INFO_MESSAGE':
      return {
        ...state,
        genericInfoMessage: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_SUCCESS':
      return {
        ...state,
        success: action.payload,
      };
    case 'SET_WARNING':
      return {
        ...state,
        warning: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export const AppContext = createContext<AppContextModel>({
  appState: appInitialState,
  appDispatch: () => {},
});

interface ResolveModel {
  resolve: null | Function,
};

function App() {

  const [appState, appDispatch] = useReducer(appReducer, appInitialState);
  const [isHelpDialogOpen, openCloseHelpDialog] = useState(false);
  const [isLoginDialogOpen, openCloseLoginDialog] = useState(false);
  const [isSNDialogOpen, openCloseSNDialog] = useState(false);
  const [snResolve, setSNResolve] = useState<ResolveModel>({
    resolve: null,
  });

  const closureSN = () => {
    let manSN = '';

    return (newSN: string | undefined) => {
      if (newSN) {
        manSN = newSN;
      } else {
        return manSN;
      }
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setGetManSN = useCallback(closureSN(), []);

  const handleSNResolve = (resolve: Function, instrumentSn: string) => {
    setSNResolve({
      resolve,
    });
  };

  const handleHelpClick = () => {
    openCloseHelpDialog(!isHelpDialogOpen);
  };

  const handleLoginOpen = () => {
    openCloseLoginDialog(!isLoginDialogOpen);
  };

  const handleSNOpen = () => {
    openCloseSNDialog(!isSNDialogOpen);
  };

  const lunchResolve = () => {
    openCloseSNDialog(!isSNDialogOpen);
    if (snResolve.resolve) {
      snResolve.resolve();
    };
  };

  const handleAlertClose = () => {
    appDispatch({
      type: 'SET_ERROR',
      payload: '',
    });
  };

  const handleConfirmClose = () => {
    appDispatch({
      type: 'SET_SUCCESS',
      payload: '',
    });
  };

  const handleWarningClose = () => {
    appDispatch({
      type: 'SET_WARNING',
      payload: '',
    });
  };

  const handleGenericMessageClose = () => {
    appDispatch({
      type: 'SET_GENERIC_INFO_MESSAGE',
      payload: '',
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
      console.log('App State', appState);
    } 
  });

  useEffect(() => {
    const savedUserData = localStorageHelper.getSaveUserData();
    // const customInstrumentSN = localStorageHelper.getSaveCustomSerialNumber();

    if(savedUserData) {
      appDispatch({
        type: 'SET_USER_DATA',
        payload: savedUserData,
      });
    };

    // if(customInstrumentSN) {
    //   appDispatch({
    //     type: 'SET_MANUAL_INSTRUMENT_SN',
    //     payload: customInstrumentSN,
    //   });
    // };
  }, []);

  return (
    <AppContext.Provider value={{
      appState,
      appDispatch,
    }}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!appState.error}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert variant="filled" severity="error">
          {appState.error}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!appState.success}
        autoHideDuration={6000}
        onClose={handleConfirmClose}
      >
        <Alert variant="filled" severity="success">
          {appState.success}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!appState.warning}
        // autoHideDuration={6000}
        onClose={handleWarningClose}
        sx={{
          minWidth: '50%',
        }}
      >
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleWarningClose}
          sx={{
            width: '100%',
          }}
        >
          {appState.warning}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <AppHeader
          handleHelpClick={handleHelpClick}
          handleLoginOpen={handleLoginOpen}
        />
        <Routes>
          <Route path="/" index element={
            <> 
              <Dialog
                fullWidth
                maxWidth="sm"
                open={isHelpDialogOpen}
                onClose={handleHelpClick}
              >
                <HelpDialog handleHelpClick={handleHelpClick} />
              </Dialog>
              <Dialog
                fullWidth
                maxWidth="xs"
                open={isLoginDialogOpen}
              >
                <LoginDialog handleLoginOpen={handleLoginOpen} />
              </Dialog>
              <Dialog
                fullWidth
                maxWidth="sm"
                open={isSNDialogOpen}
                onClose={handleSNOpen}
                sx={{zIndex: (theme) => theme.zIndex.modal + 2}}
              >
                <SNDialog
                  handleSNOpen={handleSNOpen}
                  lunchResolve={lunchResolve}
                  setGetManSN={setGetManSN}
                />
              </Dialog>
              <Dialog
                fullWidth
                maxWidth="sm"
                open={!!appState.genericInfoMessage}
                onClose={handleGenericMessageClose}
                sx={{zIndex: (theme) => theme.zIndex.modal + 2}}
              >
                <GenericMessageDialog
                  handleMessageDialogOpen={handleGenericMessageClose}
                  genericMessage={appState.genericInfoMessage}
                />
              </Dialog>
              <FilePicker
                handleLoginOpen={handleLoginOpen}
                handleSNOpen={handleSNOpen}
                setSNResolve={handleSNResolve}
                setGetManSN={setGetManSN}
              />
              {
                appState.csvData.titlesObjArr.length && !appState.isLoading ?
                <DataBlock /> :
                <DropZone
                  handleLoginOpen={handleLoginOpen}
                  handleSNOpen={handleSNOpen}
                  setSNResolve={handleSNResolve}
                  setGetManSN={setGetManSN}
                />
              }
            </>}
          />
          <Route path="/dashboard" element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          } />
          <Route path="/dashboard/:md5/:uid" element={
            <RequireAuth>
              <UploadInfo />
            </RequireAuth>
          } />
          <Route path="/instruments" element={
            <RequireAuth>
              <Instruments />
            </RequireAuth>
          } />
          <Route path="/instruments/:hwId" element={
            <RequireAuth>
              <Instrument />
            </RequireAuth>
          } />
        </Routes>
        <Footer />
      </Container>
    </AppContext.Provider>
  );
};

export default App;
