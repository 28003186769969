import { TTabPanel } from './TTabPanel';
import Box from '@mui/material/Box';


export default function TabPanel(props: TTabPanel) {
  const {
    children,
    value,
    index, 
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`pathlight-tabpanel-${index}`}
      aria-labelledby={`pathlight-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight: 500 }}>
          {children}
        </Box>
      )}
    </div>
  );
}