const sortKeys = (srcArr: Record<string, any>[]) => {
  return srcArr.sort((a, b) => a.x - b.x);
};

const addEmptyCell = (srcArr: Record<string, any>[], refArr: number[]) => {

  const fullRowArr: Record<string, any>[] = refArr.map(item => ({
    value: '',
    clr: '#fff',
    x: item + 1,
  }));

  srcArr.forEach(dataObj => {
    fullRowArr[dataObj.x - 1] = dataObj;
  });

  return fullRowArr;
};


export default function createChartsData(srcData: Record<string, any>[]) {

    const rowsObj = srcData.reduce((acum: Record<string, any[]>, item: Record<string, any>) => {
      if(!item.y || !item.x) {
        return acum
      }

      const yKey = item.y;

      if(acum[yKey]) {
        acum[yKey].push(item);
      } else {
        acum[yKey] = [item];
      };

      return acum
    }, {});

    const xAxisLength = Object.values(rowsObj)
      .reduce((acum: number, item: any) => acum >= item.length ? acum : item.length, 0);
    const refArr = Array.from(Array(xAxisLength).keys());

    const rowObjKeys =  Object.keys(rowsObj);

    rowObjKeys.forEach((key: string) => {
      rowsObj[key] = addEmptyCell(sortKeys(rowsObj[key]), refArr);
    });

    return rowsObj;
};          