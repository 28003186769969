import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import api from '../../api/api';
import { TSNDialog } from './TSNDialog';

interface textInputs {
  instrumentSN: string;
};

export default function SNDialog(props: TSNDialog) {
  const {
    handleSNOpen,
    lunchResolve,
    setGetManSN,
  } = props;

  const [inputValues, setInputValues] = useState<textInputs>({
    instrumentSN: '',
  });

  // const [isLoading, setLoading] = useState(false);

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSaveNumber = () => {
    if(inputValues.instrumentSN) {
      setGetManSN(inputValues.instrumentSN);
      lunchResolve();
    } 
  };

  return (
    <>
      <DialogTitle>
        Please enter Instrument Serial Number
        <IconButton
          aria-label="close"
          onClick={handleSNOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {
              m: '16px 0',
            }
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            // disabled={isLoading}
            name="instrumentSN"
            label="Instrument Serial Number"
            type="text"
            fullWidth
            onChange={handleTextInput}
            value={inputValues.instrumentSN}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          // disabled={isLoading}
          onClick={onSaveNumber}
        >
          Cancel
        </Button>
        <Button
          disabled={!inputValues.instrumentSN}
          onClick={onSaveNumber}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};