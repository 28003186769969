import convertTableHeaderNames from './convertTableHeaderNames';

export default function createTableHeaders(dataRow: Record<string, any>): Record<string, any>[] {
  const titles = Object.keys(dataRow);
  const tableHeaders = titles.map(title => ({
    header: convertTableHeaderNames(title),
    accessorKey: title,
  }));

  return tableHeaders;
};