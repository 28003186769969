import api from "../api/api";

export const saveUploadInfoToFirestore = (
  fileMd5: string,
  fileName: string,
  userEmail: string,
  instrumentSn: string,
  laboratories: Record<string, any>[],
  source: string,
) => (
  api.firebase.saveUpload({
      fileMd5,
      fileName,
      userEmail,
      instrumentSn,
      laboratories,
      source,
  })
);

export const getEditsHistory = (fileMd5: string) => (
  api.firebase.getEdits(fileMd5)
);

export default saveUploadInfoToFirestore;

