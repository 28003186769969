// import React, { useContext } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MaterialReactTable from 'material-react-table';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { AppContext } from '../../../../App';
// import { RESULT_TABLE_DATA_KEYS } from '../../../../constants/tableKeys';

interface TApproveDialog {
  handleDialogOpen: Function;
  tableHeaders: any;
  currentRow: Record<string, any>[];
};

export default function ApproveDialog(props: TApproveDialog) {
  const {
    handleDialogOpen,
    tableHeaders,
    currentRow,
  } = props;

//   const {
//     appState: {
//       csvData: {
//         editsHistory,
//       },
//     },
//   } = useContext(AppContext);

//   const {
//     NOTES,
//     ORIGINAL_RESULT,
//     RESULT,
//     TARGET,
//     USER,
//     UPLOAD_TIME,
//     WELLMAP,
//   } = RESULT_TABLE_DATA_KEYS;

  // console.log('!!Edits Arr', editsArr);

  return (
    <>
      <DialogTitle>
        Approve / Decline Edit
        <IconButton
          aria-label="close"
          onClick={() => handleDialogOpen({isOpen: false})}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
          <MaterialReactTable
            columns={tableHeaders}
            data={currentRow}
            enablePagination={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
          />
        <Box
          sx={{
            padding: '24px 0 24px',
            display: 'flex',
          }}
        >
          <Button
            color="success"
            variant="contained"
            disableElevation
            sx={{
              margin: '0 16px 0 auto',
            }}
          >
            Approve Edit
          </Button>
          <Button
            color="error"
            variant="contained"
            disableElevation
          >
            Decline Edit
          </Button>
        </Box>
        </DialogContent>
    </>
  );
};