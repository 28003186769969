import api from "../api/api";
import b64EncodeUnicode from "./b64EncodeUnicode";

const sendSrcCsvToBucket = (csvFileName: string, csvRawData: string) => {

    return api.rApi.sendSrcCsv({
      fileName: csvFileName,
      textB64: b64EncodeUnicode(csvRawData),
    });
  };

  export default sendSrcCsvToBucket;