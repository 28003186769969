import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../../App';
import { useParams } from 'react-router-dom';
import api from '../../../api/api';
import MaterialReactTable from 'material-react-table';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function UploadInfo() {
  const {
    appState: {
      dashboardData: {
        currentUploadData,
      }
    },
    appDispatch,
  } = useContext(AppContext);

  const {md5, uid} = useParams();

  useEffect(() =>  {
    api.firebase.getUploadInfo({
      md5,
      uid,
    })
      .then(resp => {
        if(resp.data) {
          appDispatch({
            type: 'SET_DASHBOARD_DATA',
            payload: {
              currentUpload: {
                md5,
                uid,
              },
              currentUploadData: resp.data.uploadEvents,
            }
          })
        }
      })
      .catch(err => {
        appDispatch({
          type: 'SET_DASHBOARD_DATA',
          payload: {
            currentUpload: null,
            currentUploadData: [],
          }
        });
        appDispatch({
          type: 'SET_ERROR',
          payload: err?.response?.data?.message || err.message,
        });
      });
  }, [appDispatch, md5, uid]);

  const tableHeaders = [
    {
      header: 'Event Time',
      accessorKey: 'uploadTime',
    },
    {
      header: 'Event',
      accessorKey: 'event',
    },
    {
      header: 'Number of rows',
      accessorKey: 'numberOfRows',
    },
    {
      header: 'Test type',
      accessorKey: 'testType',
    },
    {
      header: 'Assay ID',
      accessorKey: 'assayId',
    },
    {
      header: 'QC Results',
      accessorKey: 'qcRun',
    },
    {
      header: 'Instrument',
      accessorKey: 'instrument',
    },
    {
      header: 'Instrument SN',
      accessorKey: 'instrumentSn',
    },
    {
      header: 'Lab Name',
      accessorKey: 'labName',
    },
    {
      header: 'User',
      accessorKey: 'user',
    },
    {
      header: 'Status code',
      accessorKey: 'statusCode',
    },
    {
      header: 'Message',
      accessorKey: 'message',
    },
    // {
    //   header: 'Source',
    //   accessorKey: 'source',
    // },
  ];

  const singleRowKeys = [
    'testType',
    'instrument',
    'instrumentSn',
    'labName',
    'numberOfRows',
    'user',
    'assayId',
    'qcRun',
  ];

  let wrkData: any[] = [];

  wrkData = useMemo(() => currentUploadData?.length ? currentUploadData.map((uploadData: Record<string, any>) => ({
      // fileName: uploadData.file_name,
      uploadTime: new Date(uploadData.date_time).toLocaleString(),
      numberOfRows: uploadData.number_rows,
      event: uploadData.event,
      testType: uploadData.test_type,
      assayId: uploadData.assay_id,
      qcRun: uploadData.qc_run,
      instrument: uploadData.instrument,
      instrumentSn: uploadData.instrument_sn,
      labName: uploadData.lab_name,
      user: uploadData.user,
      statusCode: uploadData.status_code,
      message: uploadData.message,
    })
  ) : [], [currentUploadData]);

  const filteredTitlesForRows: Record<string, any>[] = [];
  const filteredTitlesForTable: any[] = [];
  let singleRowData: Record<string, any>[] = []

  if (wrkData.length) {
    tableHeaders.forEach(dataObj => {
      if(singleRowKeys.includes(dataObj.accessorKey)) {
        filteredTitlesForRows.push(dataObj);
      } else {
        filteredTitlesForTable.push(dataObj);
      }
    });

    singleRowData = filteredTitlesForRows.map((keyObj: Record<string, any>) => ({
      title: keyObj.header,
      value: wrkData[0][keyObj.accessorKey],
    }));
  }

  return (
    <Box>
      <Paper sx={{ borderRadius: '4px 4px 0 0' }}>
        <Typography
          variant='h6'
          gutterBottom
          sx={{
            padding: '16px 0 0 24px'
          }}
        >
          Upload Info{Boolean(currentUploadData?.length) && ` for file ${currentUploadData[0].file_name}`}
        </Typography>
        {Boolean(currentUploadData?.length) && singleRowData.map((rowObj: Record<string, any>) => (
          Boolean(rowObj.value) && <Box
            key={rowObj.title}
            sx={{
              padding: '8px 0 0 24px'
            }}
          >
            <Typography
              variant="body1"
              component="span"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {rowObj.title}
            </Typography>
            <Typography variant="body1" component="span">
              {`: ${rowObj.value}`}
            </Typography>
          </Box>)
        )}
        <MaterialReactTable
          columns={filteredTitlesForTable}
          data={wrkData}
          enableEditing={false}
          enableColumnOrdering={false}
          enableGlobalFilter={false}
          initialState={{
            ...{
              density: 'compact',
              pagination: {pageSize: 50, pageIndex: 0},
            }
          }}
        />
      </Paper>
    </Box>
  );
}