const b64EncodeUnicode = (str: string) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  // 
  return (
    window.btoa(
      encodeURIComponent(str)
      .replaceAll('%0D%0A', '%0A')
      .replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(parseInt(p1, 16)))
    )
  );
};

export default b64EncodeUnicode;