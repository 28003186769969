import { useContext } from 'react';
import { AppContext } from '../../../../App';
import MaterialReactTable, {
  // MaterialReactTableProps,
  // MRT_Row
} from 'material-react-table';
// import Box from '@mui/material/Box';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import Edit from '@mui/icons-material/Edit';
// import Delete from '@mui/icons-material/Delete';
// import api from '../../../../api/api';
import createColumnsVisibilityConfig from '../../../../utils/createColumnsVisibilityConfig';
// import createDataForApi from '../../../../utils/createDataForApi';
// import createWebhookData from '../../../../utils/createWebhookData';
import TEST_TYPES from '../../../../constants/testTypes';
import COLUMNS_VISIBLE_BY_DEFAULT from '../../../../constants/columnsVisibleByDefault';


export default function MReactTable() {

  const {
    appState: {
      // csvData,
      csvData: {
        // csvFileName,
        csvHeadersObj,
        titlesObjArr,
        tableDataArr,
        // results,
      },
      // userData,
    },
    // appDispatch,
  } = useContext(AppContext);
  const wrkData = [...tableDataArr];

  // const handleSaveRowEdits: MaterialReactTableProps<any>['onEditingRowSave'] =
  //   async ({ exitEditingMode, row, values }) => {
  //     const srcData = {...wrkData[row.index]);
  //     const dataForSave1 = srcData['wellposition']; // Names from config
  //     const dataForSave2 = srcData['result']; // Names from config
  //     
  //     await api.firebase.saveEdit({uploadId: stringFromContext, result: dataForSave2, wellMap: dataForSave1, notes: string})
  //     
  //     wrkData[row.index] = values;

  //     const instrumentType = csvHeadersObj['Instrument Type'];
  //     const instrumentSN = csvHeadersObj['Instrument Serial Number'];

  //     const dataForApi = createDataForApi(
  //       instrumentType,
  //       instrumentSN,
  //       csvFileName,
  //       csvHeadersObj.sampleType,
  //       false,
  //       results?.sample_obj.lab_name,
  //       wrkData,
  //     );

  //     const webhookData = createWebhookData(
  //       'File Loaded',
  //       instrumentType,
  //       csvHeadersObj.sampleType.toLowerCase(),
  //       csvFileName,
  //       instrumentSN,
  //       (userData?.laboratories ? userData.laboratories[0]?.labName : 'N/A'),
  //       tableDataArr.length,
  //       (userData?.email ? userData.email : 'M/A'),
  //       (new Date(Date.now()).toISOString()),
  //       200,
  //     );

  //     appDispatch({
  //       type: 'SET_LOADING',
  //       payload: true,
  //     });

  //     const resultsFromApi = dataForApi ?
  //       await api.rApiResults.getResults(dataForApi)
  //         .catch(err => {
  //           console.log('Err', err.message);

  //           webhookData.message = err.message;
  //           webhookData.status_code = err.response.status;
  //           api.webhook.sendWebhook(webhookData)
  //             .then()
  //             .catch(err => console.log(err));

  //           appDispatch({
  //             type: 'SET_ERROR',
  //             payload: err.message,
  //           })
  //         })
  //         .finally(() => appDispatch({
  //             type: 'SET_LOADING',
  //             payload: false,
  //           })
  //         ) : null;
        

  //     console.log('results', resultsFromApi);

  //     if(resultsFromApi) {
  //       webhookData.status_code = resultsFromApi.status;          
  //       api.webhook.sendWebhook(webhookData)
  //         .then()
  //         .catch(err => console.log(err));
  //     };

  //     const editedCsvData = {
  //       ...csvData,
  //       tableDataArr: wrkData,
  //       results: resultsFromApi ? resultsFromApi.data : resultsFromApi,
  //     };

  //     appDispatch({
  //       type: 'SET_CSV_DATA',
  //       payload: editedCsvData,
  //     });
  //     exitEditingMode(); //required to exit editing mode and close modal
  //   };

  // const handleDeleteRow = (row: MRT_Row<any>) => {
  //   if (
  //     !window.confirm('Are you sure you want to delete row?')
  //   ) {
  //     return;
  //   }
  //   wrkData.splice(row.index, 1);
  //   const editedCsvData = {
  //     ...csvData,
  //     tableDataArr: wrkData,
  //   };
  //   appDispatch({
  //     type: 'SET_CSV_DATA',
  //     payload: editedCsvData,
  //   });
  // };
  
  const isToxData = (csvHeadersObj.sampleType.toLocaleLowerCase() === TEST_TYPES.CONCENTRATION);

  let visibilityConfig;
  if(!isToxData) {
    visibilityConfig = {
      columnVisibility: createColumnsVisibilityConfig(titlesObjArr, COLUMNS_VISIBLE_BY_DEFAULT)
    };
  };
  
  return (
    <MaterialReactTable
      columns={titlesObjArr}
      data={wrkData}
      // enableRowSelection //enable some features
      editingMode="modal" //default
      enableEditing={false}
      // onEditingRowSave={handleSaveRowEdits}
      enableColumnOrdering={false}
      enableGlobalFilter={false} //turn off a feature
      initialState={{
        ...visibilityConfig,
        ...{
          density: 'compact',
          pagination: {pageSize: 50, pageIndex: 0},
        }
      }} //hide columns by default
      // renderRowActions={({ row, table }) => (
      //   <Box sx={{ display: 'flex', gap: '1rem' }}>
      //     <Tooltip arrow placement="left" title="Edit">
      //       <IconButton onClick={() => table.setEditingRow(row)}>
      //         <Edit />
      //       </IconButton>
      //     </Tooltip>
      //     {/* <Tooltip arrow placement="right" title="Delete">
      //       <IconButton color="error" onClick={() => handleDeleteRow(row)}>
      //         <Delete />
      //       </IconButton>
      //     </Tooltip> */}
      //   </Box>)
      // }
    />
  );
};
