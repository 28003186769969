import { useContext } from 'react';
import { AppContext } from '../../../../App';
import api from '../../../../api/api';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MaterialReactTable from 'material-react-table';
import createTableHeaders from '../../../../utils/createTableHeaders';
import createColumnsVisibilityConfig from '../../../../utils/createColumnsVisibilityConfig';
import TEST_TYPES from '../../../../constants/testTypes';
import COLUMNS_CUTOFFS_VISIBLE_BY_DEFAULT from '../../../../constants/columnsCutoffsVisibleByDefault';


export default function CutoffsTable() {

  const {
    appState: {
      csvData,
      csvData: {
        csvHeadersObj,
        results,
        dataForApi,
      }
    },
    appDispatch,
  } = useContext(AppContext);

  let wrkData: any[] = [];
  let tableHeaders: any[] = [];

  if(results?.cutoffs) {
    tableHeaders = createTableHeaders(results.cutoffs[0]);
    wrkData = [...results.cutoffs];
  };

  const isToxData = (csvHeadersObj.sampleType.toLocaleLowerCase() === TEST_TYPES.CONCENTRATION);

  let visibilityConfig;
  if(!isToxData) {
    visibilityConfig = {
      columnVisibility: createColumnsVisibilityConfig(tableHeaders, COLUMNS_CUTOFFS_VISIBLE_BY_DEFAULT)
    };
  };

  const updateConfig = async () => {
    appDispatch({
      type: 'SET_LOADING',
      payload: true,
    });

    const resp = await api.rApi.updateConfig()
      .catch(err => {
        console.log(err);
        appDispatch({
          type: 'SET_ERROR',
          payload: err.message,
        });   
      })
    if (resp && resp.data) {
      const lastUpdatedTime = new Date(resp.data?.lastUpdated).toLocaleString();
      appDispatch({
        type: 'SET_UPDATE_CONFIG_TIME',
        payload: lastUpdatedTime,
      });
    };

    const resultsFromApi = dataForApi ?
      await api.rApi.getResults(dataForApi)
        .catch(err => {
          console.log('Err', err.message);

          // webhookData.message = err.message;
          // webhookData.status_code = err.response.status;
          // api.webhook.sendWebhook(webhookData)
          //   .then()
          //   .catch(err => console.log(err));

          if(err.response.status === 503) {
            appDispatch({
              type: 'SET_WARNING',
              payload: err?.response?.data?.error,
            })
          };

          appDispatch({
            type: 'SET_ERROR',
            payload: err.message,
          })
        })
        .finally(() => appDispatch({
            type: 'SET_LOADING',
            payload: false,
          })
        ) : null;

    if(!resultsFromApi) {
      appDispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    };

    appDispatch({
      type: 'SET_CSV_DATA',
      payload: {
        ...csvData,
        results: resultsFromApi ? resultsFromApi.data : resultsFromApi,
      },
    });
  };

  return (
    <>
      <MaterialReactTable
        columns={tableHeaders}
        data={wrkData}
        // enableRowSelection //enable some features
        editingMode="modal" //default
        enableEditing={false}
        // onEditingRowSave={handleSaveRowEdits}
        enableColumnOrdering={false}
        enableGlobalFilter={false} //turn off a feature
        initialState={{
          ...visibilityConfig,
          ...{
            density: 'compact',
            pagination: {pageSize: 50, pageIndex: 0},
          }
        }} //hide columns by default
      />
      <Box
        sx={{
          padding: '16px 8px',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={updateConfig}
        >
          Update config
        </Button>
      </Box>
    </>
  );
};
