import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../App';
import MaterialReactTable from 'material-react-table';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import History from '@mui/icons-material/History';
import CheckBoxRounded from '@mui/icons-material/CheckBoxOutlined';
import HistoryDialog from '../Results/HistoryDialog';
import ApproveDialog from '../Results/ApproveDialog';
import { HandleHistoryModal, HandleApproveModal } from '../../../../models/HandleModalsModels';
import createTableHeaders from '../../../../utils/createTableHeaders';
import createCsvFile from '../../../../utils/createCsvFile';
import createTxtFileBody from '../../../../utils/createTextFileBody';
import createColumnsVisibilityConfig from '../../../../utils/createColumnsVisibilityConfig';
import TEST_TYPES from '../../../../constants/testTypes';
import COLUMNS_RESULT_VISIBLE_BY_DEFAULT from '../../../../constants/columnsResultVisibleByDefault';
import {
  RESULT_TABLE_DATA_KEYS,
  MANUAL_EDIT,
  EDIT_SELECT_OPTIONS,
} from '../../../../constants/tableKeys';

const {
  FINAL_RESULT,
  NOTES,
  QC_RESULT,
  STATUS,
  TEST,
  WELLPOSITION,
} = RESULT_TABLE_DATA_KEYS;

export default function EditsTable() {

  const {
    appState: {
      csvData: {
        csvFileName,
        csvHeadersObj,
        results,
        editsHistory,
      },
    },
  } = useContext(AppContext);

  const [historyState, handleHistoryState] = useState<HandleHistoryModal>({
    isOpen: false,
    currentWellMap: '',
    currentTarget: '',
  });

  const [approveState, handleApproveState] = useState<HandleApproveModal>({
    isOpen: false,
    currentRowIndex: 0,
  });

  const handleApproveDialogState = (newState: Record<string, any>) => {
    handleApproveState({
      ...approveState,
      ...newState,
    });
  };

  const handleHistoryDialogState = (newState: Record<string, any>) => {
    handleHistoryState({
      ...historyState,
      ...newState,
    });
  };

  let wrkData: any[] = [];
  let tableHeaders: any[] = [];

  const createEditsHistory = (editHistory: Record<string, any> | undefined): Record<string, any>[] | undefined => {
    if(!editHistory || !Object.keys(editHistory).length) return;

    const wellMaps = Object.keys(editHistory);
    const wrkDataArr: Record<string, any>[] = [];

    const {
      RESULT: RESULT_VALUES,
      QC_CONTROL: CONTROL_VALUES,
    } = EDIT_SELECT_OPTIONS;

    const resultValuesArr = Object.values(RESULT_VALUES)
      .map(value => value.toLowerCase());
    const qcValuesArr = Object.values(CONTROL_VALUES)
      .map(value => value.toLowerCase());

    wellMaps.forEach((wellMap: string) => {
      // Finding all targets as an array = ['FluA', 'FluB'];
      const targets: string[] = Object.keys(editHistory[wellMap].targets);

      targets.forEach((target: string) => {
        let resultKey;

        if(resultValuesArr.includes(editHistory[wellMap].targets[target].toLowerCase())) {
          resultKey = FINAL_RESULT;
        } else if(qcValuesArr.includes(editHistory[wellMap].targets[target].toLowerCase())) {
          resultKey = QC_RESULT;
        } else {
          // For a future cases
          resultKey = FINAL_RESULT;
        };

        const wrkDataObject = {
          [WELLPOSITION]: wellMap,
          [TEST]: target,
          [NOTES]: editHistory[wellMap].notes[target],
          // Need to implement dynamic key definition FINAL_RESULT / QC_RESULT
          // Depending on VALUE 'Detected' / 'Not Detected' || 'PASS' / 'FAIL'
          [resultKey]: editHistory[wellMap].targets[target],
          // [RESULT]: editHistory[wellMap].targets[target].toLowerCase().replace(' ', '_'),
          [STATUS]: MANUAL_EDIT,
        };

        wrkDataArr.push(wrkDataObject);
      });
    })

    return wrkDataArr;
  };

  if(results?.sample_obj) {
    tableHeaders = createTableHeaders(results.sample_obj.results[1]);
    const data = [...results.sample_obj.results];
    let editedWrkData: Record<string, any>[] = [];

    const editsHistoryArr = createEditsHistory(editsHistory?.finalEdits);
    // console.log('!!!testHistoryArr!!!', editsHistoryArr);

    if(editsHistoryArr) {
      data.forEach(dataObj => {
        let finalObj = null;

        editsHistoryArr.forEach(editedObj => { 
          if (dataObj.wellposition === editedObj.wellposition && dataObj.test === editedObj.test) {
            finalObj = {
              ...dataObj,
              ...editedObj,
            };
          }
        });

        if(finalObj) {
          editedWrkData.push(finalObj);
        }
      });
    };
    // console.log('!!!editedWrkData!!!', editedWrkData);

    wrkData = editedWrkData ? editedWrkData : data;
  };

  const isToxData = (csvHeadersObj.sampleType.toLocaleLowerCase() === TEST_TYPES.CONCENTRATION);

  let visibilityConfig;
  if(!isToxData) {
    visibilityConfig = {
      columnVisibility: createColumnsVisibilityConfig(tableHeaders, COLUMNS_RESULT_VISIBLE_BY_DEFAULT)
    };
  };

  const downloadCsvFile = () => {
    if(!results?.sample_obj?.results) return;

    // const tableHeaders = createTableHeaders(results.sample_obj.results[1]);
    const csvFile = createCsvFile(null, tableHeaders, wrkData);
    const textFileBlob = createTxtFileBody(csvFile);

    if(textFileBlob) {
      const downloadCsvFileUrl = window.URL.createObjectURL(textFileBlob);
      const link = document.createElement('a');
      link.href = downloadCsvFileUrl;
      link.download = `${csvFileName.replace('.csv', '')}_edits.csv`;
      link.click();
      URL.revokeObjectURL(link.href);
    } else {

      return;
    };  
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={historyState.isOpen || approveState.isOpen}
      >
        {Boolean(historyState.isOpen) && <HistoryDialog
          handleDialogOpen={handleHistoryDialogState}
          currentWellMap={historyState.currentWellMap}
          currentTarget={historyState.currentTarget}
        />}
        {Boolean(approveState.isOpen) && <ApproveDialog
          handleDialogOpen={handleApproveDialogState}
          tableHeaders={tableHeaders}
          currentRow={[wrkData[approveState.currentRowIndex]]}
        />}
      </Dialog>
      <Box>
        <Paper sx={{
          borderRadius: '4px 4px 0 0',
          padding: '8px  0 8px',
        }}>
          <Button
            color="success"
            variant="contained"
            disableElevation
            size='large'
            sx={{
              marginRight: 1,
              marginLeft: 1,
            }}
            endIcon={<DownloadIcon />}
            onClick={downloadCsvFile}
          >
            Download Edits as CSV file
          </Button>
        </Paper>
      </Box>
      <MaterialReactTable
        columns={tableHeaders}
        data={wrkData}
        enableEditing={!isToxData}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            backgroundColor: row.original[STATUS] === MANUAL_EDIT ? 'khaki' : 'transparent',
          }
        })}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="View history">
              <IconButton onClick={() => handleHistoryDialogState({
                  isOpen: true,
                  currentWellMap: row.original[WELLPOSITION],
                  currentTarget: row.original[TEST],
                })}>
                <History />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title="Review changes">
              <IconButton onClick={() => {
                // console.log(wrkData[row.index]);
                handleApproveDialogState({
                  isOpen: true,
                  currentRowIndex: row.index,
                })
              }}>
                <CheckBoxRounded />
              </IconButton>
            </Tooltip>
          </Box>)
        }
        enableColumnOrdering={false}
        enableGlobalFilter={false}
        initialState={{
          ...visibilityConfig,
          ...{
            density: 'compact',
            pagination: {pageSize: 50, pageIndex: 0},
          }
        }}
      />
    </>
  );
};
