import { AxiosInstance } from 'axios';


const assay = (httpClient: AxiosInstance, getAssayUrl: string) => ({
  getAssayType(srcJson: {[key:string]: any}): Promise<any> {

    return httpClient.post(`/${getAssayUrl}`, srcJson);
  }
});

export default assay;