import { WebhookModel } from "../models/WebhookModel";
import trimString from './trimString';

export default function createWebhookData(
  event: string,
  instrument: string,
  testType: string,
  fileName: string,
  instrumentSn: string,
  labName = 'N/A',
  numberOfRows: number,
  userEmail: string,
  dateTime: string,
  statueCode: number,
  errMessage = 'N/A'
): WebhookModel {
  
  return {
    event: event,
    instrument: instrument ? trimString(instrument) : instrument,
    test_type: testType,
    file_name: fileName,
    instrument_sn: instrumentSn ? trimString(instrumentSn) : 'UNKNOWN',
    lab_name: labName,
    number_rows: numberOfRows,
    user: userEmail,
    date_time: dateTime,
    status_code: statueCode,
    message: errMessage,
  };
};