const REQ_KEYS = {
  MOLECULAR: [
    'well',
    'well position',
    'sample',
    'target',
    'cq',
  ],
  CONCENRTRATION: [
    'samplename',
    'compound',
    'conc',
    // 'concentration',
  ],
  QC_RUN: [
    'low',
    'high',
    'mid',
    'qc',
  ],
  QC_CONC: {
    LOW: 'low',
    HIGH: 'high',
    MID: 'mid',
    QC: 'qc',
  }
};

export default REQ_KEYS;