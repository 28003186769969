import { AxiosInstance } from 'axios';
import URLS from '../../constants/urls';
import queryBuilder from '../../utils/queryBuilder';
import {
  UploadInfoModel,
  UploadInfoLogModel,
  EditInfoModel,
  UpdateInstrumentConfigModel,
} from '../../models/FirebaseModels';

const {
  FIREBASE: { 
    SAVE_UPLOAD,
    SAVE_UPLOAD_INFO,
    GET_UPLOADS,
    GET_UPLOAD_INFO,
    GET_UPLOAD_BY_INSTRUMENT_SN,
    GET_UPLOAD_BY_LAB_ID,
    SAVE_EDIT_INFO,
    GET_EDITS,
    DETECT_INSTRUMENT,
    CONVERT_CSV,
    GET_LAB_NAME,
    SEND_TO_LIS,
    GET_LIS_UPLOAD_STATUS,
    TO_BEACON_QC,
    INSTRUMENTS,
    UPDATE_INSTRUMENT_CONFIG,
    UPDATE_INSTRUMENT_CONFIG_FILE,
    GET_INSTRUMENT_CONFIG,
  }
} = URLS;

const firebase = (httpClient: AxiosInstance) => ({
  saveUpload(requestBody: UploadInfoModel): Promise<any> {

    return httpClient.post(`/${SAVE_UPLOAD}`, requestBody);
  },

  saveUploadInfo(requestBody: UploadInfoLogModel): Promise<any> {

    return httpClient.post(`/${SAVE_UPLOAD_INFO}`, requestBody);
  },

  getUploads(query: Record<string, any>): Promise<any> {
    const queryString = queryBuilder(query);

    return httpClient.get(`/${GET_UPLOADS}${queryString}`);
  },

  getUploadInfo(query: Record<string, any>): Promise<any> {
    const queryString = queryBuilder(query);

    return httpClient.get(`/${GET_UPLOAD_INFO}${queryString}`);
  },

  getUploadsByInstrumentSn(instrumentSn: string): Promise<any> {

    return httpClient.get(`/${GET_UPLOAD_BY_INSTRUMENT_SN}/${instrumentSn}`);
  },

  getUploadsByLabId(labId: string): Promise<any> {

    return httpClient.get(`/${GET_UPLOAD_BY_LAB_ID}/${labId}`);
  },

  saveEdit(requestBody: EditInfoModel): Promise<any> {

    return httpClient.post(`/${SAVE_EDIT_INFO}`, requestBody);
  },

  getEdits(fileMd5: string): Promise<any> {

    return httpClient.get(`/${GET_EDITS}/${fileMd5}`);
  },

  detectInstrument(base64string: string): Promise<any> {

    return httpClient.post(`/${DETECT_INSTRUMENT}`, {
      textB64: base64string,
    });
  },

  convertCsv(base64string: string, instrumentType: string): Promise<any> {

    return httpClient.post(`/${CONVERT_CSV}`, {
      textB64: base64string,
      instrumentType,
    });
  },

  getLabName(instrumentSn: string): Promise<any> {

    return httpClient.get(`/${GET_LAB_NAME}/${instrumentSn}`);
  },

  sendToLis(lisResultsObj: Record<string, any>): Promise<any> {

    return httpClient.post(`/${SEND_TO_LIS}`, lisResultsObj);
  },

  getLisUploadStatus(uploadId: string): Promise<any> {
    
    return httpClient.get(`/${GET_LIS_UPLOAD_STATUS}/${uploadId}`);
  },

  sendToQc(lisResultsObj: Record<string, any>): Promise<any> {

    return httpClient.post(`/${TO_BEACON_QC}`, lisResultsObj);
  },

  getInstruments(): Promise<any> {
    
    return httpClient.get(`/${INSTRUMENTS}`);
  },

  updateInstrument(instrumentConfigData: UpdateInstrumentConfigModel): Promise<any> {
    
    return httpClient.post(`/${UPDATE_INSTRUMENT_CONFIG}`, instrumentConfigData);
  },

  updateInstrumentConfig(formDataObj: Record<string, any>, query: Record<string, any>): Promise<any> {
    const queryString = queryBuilder(query);

    return httpClient.post(`/${UPDATE_INSTRUMENT_CONFIG_FILE}${queryString}`, formDataObj);
  },

  getInstrumentConfig(query: Record<string, any>): Promise<any> {
    const queryString = queryBuilder(query);

    return httpClient.get(`/${GET_INSTRUMENT_CONFIG}${queryString}`);
  }
});

export default firebase;
