import { AxiosInstance } from 'axios';
import { WebhookModel } from '../../models/WebhookModel';

const webhooks = (httpClient: AxiosInstance) => ({
  sendWebhook(webhookObj: WebhookModel): Promise<any> {

    return httpClient.post('/', webhookObj);
  }
});

export default webhooks;