import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TChartContainer {
  chartTitle?: string;
  children: React.ReactNode;
};

export default function ChartContainer(props: TChartContainer) {

  const {chartTitle} = props;

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 500,
    }}>
      {Boolean(chartTitle) && (
        <Typography gutterBottom variant='h5'>
          {`Test: ${chartTitle}`}
        </Typography>
      )}
      {props.children}
    </Box>
  )
};