import { AxiosInstance } from 'axios';
import b64EncodeUnicode from '../../utils/b64EncodeUnicode';
import URLS from '../../constants/urls';
import { ZendeskTicketModel, ZendeskRequestModel } from '../../models/ZendeskTicketModel';
import zendeskToken from '../../constants/zendeskToken';

const {ZENDESK: { CREATE_TICKET, CREATE_REQUEST }} = URLS;

const zendesk = (httpClient: AxiosInstance) => ({
  createTicket(ticketBody: ZendeskTicketModel, userEmail: string): Promise<any> {
    const authData = `${userEmail}/token:${zendeskToken}`;

    return httpClient.post(`/${CREATE_TICKET}`, JSON.stringify(ticketBody), {
      headers: {
        'Authorization': `Basic ${b64EncodeUnicode(authData)}`,
      }
    });
  },
  createRequest(requestBody: ZendeskRequestModel): Promise<any> {

    return httpClient.post(`/${CREATE_REQUEST}`, requestBody);
  },
});

export default zendesk;