const jsoncParser = (jsoncSrc: string): Record<string, any> | null => {
    const jsonLines = jsoncSrc.split('\n');

    const srcLinesWithoutComments = jsonLines
      .map(line => {
        // Remove single line comments
        let singleLineCommentIndex = line.indexOf('//');

        // check whether it comment or url (http://)
        const urlIndex = line.indexOf('://');

        if (singleLineCommentIndex === urlIndex + 1) {
          singleLineCommentIndex = line.indexOf('//', urlIndex + 2);
        }

        if (singleLineCommentIndex !== -1) {
          return line.substring(0, singleLineCommentIndex);
        }

        // Return the line if no single line comment was found
        return line;
      });
    
    // Join back all lines
    const rawJson = srcLinesWithoutComments.join('');
      
    // Remove potentially multi-line comments
    const cleanRawJson = rawJson.replace(/\/\*[\s\S]*?\*\//g, '');
    
    // Remove empty spaces
    const trimedCleanRawJson = cleanRawJson.replace(/\s/g, '');
    
    // Assembling native JS json object

    let jsonOutput = null;

    try {
      jsonOutput = JSON.parse(trimedCleanRawJson);
    } catch (err) {
        console.log(err);
    }

    return jsonOutput;
  };

  export default jsoncParser;
