import { useContext, useMemo } from 'react';
import { AppContext } from '../../../../App';
import Box from '@mui/material/Box';
import WellDiagram from './WellDiagram';
import createChartsData from "../../../../utils/createChartsData";
import getWellMapName from '../../../../utils/getWellMapName';

export default function WellMap() {
  const {
    appState: {
      csvData: {
        results
      }
    }
  } = useContext(AppContext);

  const srcDataArr: Record<string, any>[] = useMemo(() => (results?.chart_obj ? results.chart_obj.map((wellDataArr: any[]) => createChartsData(wellDataArr)) : []), [results]);

  // console.log('!!!srcDataArr!!!', srcDataArr);
  // Need to switch from srcDataItem?.['A']?.[0] to first element in Array - there are cases when A is not exist

  return (
    <Box>
      {Boolean(srcDataArr.length) &&
        srcDataArr.map((srcDataItem, index) => (
          <WellDiagram
            key={index}
            srcData={srcDataItem}
            chartTitle={srcDataArr.length > 1 ? getWellMapName(Object.values(srcDataItem)?.[0]?.[0]) : 'Multiple tests'}
          />
        ))
      }
    </Box>
  )
};