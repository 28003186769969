import Dropzone from 'react-dropzone';
import Box from '@mui/material/Box';
import UploadFile from '@mui/icons-material/UploadFile';
import { TDragAndDrop } from './TDragAndDropArea';


export default function DragAndDropArea(props: TDragAndDrop) {

  const { handleFiles, isUserAuthorized, handleLoginOpen } = props;

  const loginAlert = () => handleLoginOpen();

  return <Dropzone onDrop={isUserAuthorized ? acceptedFiles => handleFiles(acceptedFiles) : loginAlert}>
    {({getRootProps, getInputProps}) => (
      <Box
        {...getRootProps()}
        sx={{
          width: 200,
          '&:hover': {
            cursor: 'pointer',
          }
        }}
      >
          {isUserAuthorized && <input {...getInputProps()} />}
          <UploadFile
            color="primary"
            sx={{ width: 200, height: 200 }}
            onClick={!isUserAuthorized ? loginAlert : undefined}
          />
      </Box>
    )}
  </Dropzone>
};