import * as Sentry from "@sentry/react";
import REQ_KEYS from "../constants/requiredCSVTableKeys";

export default function processCsv(csvTextBody: string, delim = ','): Record<string, any> | null {

  const DISABLED_FOR_EDIT_COLS = [
    'Well',
    'Well Position',
    // 'Sample',
    // 'Target',
    'Reporter',
    // 'Amp Status',
    // 'Amp Score',
    // 'Cq',
    // 'Cq Confidence',
    'Threshold',
    'Baseline Start',
    'Baseline End',
  ];
  const TYPE_TOX = 'Concentration';
  const TYPE_MOLECULAR = 'Molecular';
  
  // const rawCsvLinesArr = csvTextBody.split('\n');
 
  const separateCsvData = (csvFileLinesArr: string[]): Record<string, any> | null => {
    const rawMolecularHeaders: string[] = [];
    const rawToxHeaders: string[] = [];
    const rawBody: string[] = [];
    let rawHeaders: string[] = [];
    let sampleType: string = '';
  
    const toxHeaderCharRg = /^(qqq|instrument)/i;

    csvFileLinesArr.forEach(line => {
      if(typeof line !== 'string') return;

      if (line.startsWith('#')) {
        rawMolecularHeaders.push(line);
      } else 
      if (line.match(toxHeaderCharRg)) {
        rawToxHeaders.push(line);
      } else {
        rawBody.push(line);
      }
    });

    if (rawMolecularHeaders.length && !rawToxHeaders.length) {
      sampleType = TYPE_MOLECULAR;
      rawHeaders = rawMolecularHeaders;
    } else
    if (rawToxHeaders.length && !rawMolecularHeaders.length) {
      sampleType = TYPE_TOX;
      rawHeaders = rawToxHeaders;
    } else {
      console.log('CSV Headers detection error');
      Sentry.captureMessage('CSV Headers detection error');
    };

    // Check CSV structure validity here
    if(!rawHeaders.length || !rawBody.length) {
      console.log('RAW CSV parsing failed - Header or Body can\'t be recognized');
      Sentry.captureMessage('RAW CSV parsing failed - Header or Body can\'t be recognized');
      return null;
    };

    if(!sampleType) {
      console.log('RAW CSV parsing failed - Sample type is UNKNOWN');
      Sentry.captureMessage('RAW CSV parsing failed - Sample type is UNKNOWN');
      return null;
    };

    return {
      rawHeaders,
      rawBody,
      sampleType,
    };
  };


  const createCsvHeaderObj = (headersArr: string[]): {[key: string]: string} => {
    const csvHeaderValues = headersArr.map(row => row.split(": "));
    const csvHeadersObj = csvHeaderValues.reduce((obj: Record<string, any>, valuesArr: string[]): Record<string, any> => {
      obj[valuesArr[0].replace('#', '').trim()] = valuesArr[1] ? valuesArr[1].trim() : '';
      return obj;
    }, {});
    return csvHeadersObj;
  };

  const createToxCsvHeaderObj = (headersArr: string[]): Record<string, any> => {
    const csvHeaderValues = headersArr.map(row => row.split(","));
    const csvHeadersObj = csvHeaderValues.reduce((obj: {[key: string]: any}, valuesArr: string[]): {[key: string]: any} => {
      const regex = /qqq|instrument/i;
      obj[valuesArr[0].replace(regex, '').trim()] = valuesArr[1] ? valuesArr[1].trim() : '';
      return obj;
    }, {});
    return csvHeadersObj;
  };


  const createTableTitlesAndRows = (csvBodyArray: string[], delim: string): string[][] => {
    const regex = /"([a-z0-9.()\\ ]([a-z0-9\-();., ]+)[a-z0-9.()\\ ])"/i;

    // const rr = /(?<=").*(?=")/; // Doesn't work in Safari
    // const regex = /".*"/;
    // const regex = /"([a-z0-9.()\\ ](.*\s+.*)[a-z0-9.()\\ ])"/gi;

    const dataArr = csvBodyArray.map( row => {

      // Need to handle \"a, b\" case
      const match = regex.exec(row);
      let rowWithMultiValuesInACell;
      
      if(match) {
        // console.log('MATCH', match);
        const repValue = match[0].replaceAll(',', '{}');
        rowWithMultiValuesInACell = row.replace(match[0], repValue);
        // console.log('MATCH REPLACED', rowWithMultiValuesInACell);
      }

      const preparedRow = rowWithMultiValuesInACell || row;
      const cleanRow = preparedRow.replaceAll('"', '').trim(); 
      const eachRow = cleanRow.split(delim);

      return eachRow;
    });

    // console.log('DATA_ARR in process csv', dataArr);
    // We need to remove empty CSV string that may present in some CSV files and which causing R API 500 Errors
    return dataArr.filter(row => row.length > 1);
  };


  const createReactMaterialTableStructure = (csvDataBodyArray: string[][], disabledCols: string[]): {
    titlesObjArr: Record<string, any>[];
    tableDataArr: Record<string, any>[];
  } => {
    if(!(Array.isArray(csvDataBodyArray) && csvDataBodyArray.length)) {
      console.log('Wrong data strucure');

      return {
        titlesObjArr: [],
        tableDataArr: [],
      };
    };

    const columnTitles = csvDataBodyArray[0];
    const dataRows = csvDataBodyArray.slice(1);

    // Formatting Titles according to Material-React-Table format
    // {
    //   header: 'First Name',
    //   accessorKey: 'firstname', 
    // },
    // {
    //   header: 'Age',
    //   accessorKey: 'age', 
    // }

    const titlesObjArr = columnTitles.map(header => {
      const flatHeader = header.split(' ').join('').toLowerCase().trim();
      return {
        header,
        accessorKey: flatHeader,
        enableEditing: !disabledCols.includes(header),
        // accessorFn: (row: {[key: string]:any}) => row[flatHeader],
        // id: flatHeader,
        // 
      };
    });

    // Formatting table data rows according to Material-React-Table format
    // {
    //   firstname: 'John',
    //   age: 30,
    // }

    const tableDataArr = dataRows.map( row => {

      const eachObject = columnTitles.reduce((obj: Record<string, any>, title: string, i): Record<string, any> => {
          const flatTitle = title.split(' ').join('').toLowerCase().trim();
          obj[flatTitle] = row[i].replaceAll('{}', ',');
          return obj;
        }, {})
        
      return eachObject;
    });

    return {
      titlesObjArr,
      tableDataArr,
    }
  };

  // Parsing starts here //

  const rawCsvLinesArr = csvTextBody.split('\n');

  const separatedCsvData = separateCsvData(rawCsvLinesArr);
  // console.log('Separated data', separatedCsvData);

  // If raw CSV data parsing failed -> Stop here and throw an Error / Create Sentry Report
  if(!separatedCsvData) return null;

  const { sampleType, rawHeaders } = separatedCsvData;

  const csvHeadersObj = sampleType === TYPE_TOX ?
    createToxCsvHeaderObj(rawHeaders) :
    createCsvHeaderObj(rawHeaders);

  // If csvHeadersObj was not created -> Stop here and send report to Sentry
  
  csvHeadersObj.sampleType = sampleType;
  // console.log('csvHeaders', csvHeadersObj);

  const csvDataBodyArray = createTableTitlesAndRows(separatedCsvData.rawBody, delim);

  // console.log('!!!csvDataBodyArray!!!', csvDataBodyArray);

  const mReactTableDataObj = createReactMaterialTableStructure(csvDataBodyArray, DISABLED_FOR_EDIT_COLS);
  // Check Required Table Keys for Molecular or Concentration types here;
  // In mReactTableDataObj.titlesObjArr obj

  const reqKeys: string[] = sampleType === TYPE_MOLECULAR ? REQ_KEYS.MOLECULAR : REQ_KEYS.CONCENRTRATION;
  const tableKeys: string[] = mReactTableDataObj.titlesObjArr.map(titleObj => titleObj.header.toLowerCase());
  const isTableKeysValid = reqKeys.every(key => tableKeys.includes(key));
  // console.log('isTableKeysValid', isTableKeysValid);

  if(!isTableKeysValid) {
    console.log('Required CSV Table Keys are missing');
    Sentry.captureMessage('Required CSV Table Keys are missing');
    return null;
  };

  return {
    csvHeadersObj,
    csvDataBodyArray,
    ...mReactTableDataObj,
  };
};

