function createColumnsVisibilityConfig(titlesObjArr: Record<string, any>[], columnsList: string[]): Record<string, any> {
  const configObj: Record<string, any> = {};
  titlesObjArr.forEach((titleObj: Record<string, any>) => {
    const { accessorKey } = titleObj;
    configObj[accessorKey] = columnsList.includes(accessorKey);
  });

  return configObj;
};

export default createColumnsVisibilityConfig;