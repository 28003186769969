const COLUMNS_VISIBLE_BY_DEFAULT = [
  'well',
  'wellposition',
  'sample',
  'target',
  'ampstatus',
  'ampscore',
  'cq',
  'cqconfidence',
];

export default COLUMNS_VISIBLE_BY_DEFAULT;