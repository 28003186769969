import { AxiosInstance } from 'axios';
import URLS from '../../constants/urls';
const {
  R_API: {
    LAB_NAME,
    RESULTS,
    SEND_TO_QC,
    SEND_TO_LIS,
    SEND_SRC_FILE,
    UPDATE_CONFIG,
    CONVERT_FOR_SHIMADZU,
    TO_BEACON_QC,
  }
} = URLS;

const rApi = (httpClient: AxiosInstance) => ({
  getLabName(params: Record<string, any>): Promise<any> {

    return httpClient.get(`/${LAB_NAME}`, {params: params});
  },
  getResults(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${RESULTS}`, srcJson);
  },
  sendToQC(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${SEND_TO_QC}`, srcJson);
  },
  sendToLis(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${SEND_TO_LIS}`, srcJson);
  },
  sendSrcCsv(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${SEND_SRC_FILE}`, srcJson);
  },
  updateConfig(): Promise<any> {

    return httpClient.get(`/${UPDATE_CONFIG}`);
  },
  getConvertedResultsShimadzu(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${CONVERT_FOR_SHIMADZU}`, srcJson);
  },
  sendToBeaconQc(srcJson: Record<string, any>): Promise<any> {

    return httpClient.post(`/${TO_BEACON_QC}`, srcJson);
  },
});

export default rApi;