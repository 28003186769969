import React, { useContext } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AppContext } from '../../../../App';
import {
  RESULT_TABLE_DATA_KEYS,
  EDIT_SELECT_OPTIONS,
  TABLE_HEADER_TITLES,
} from '../../../../constants/tableKeys';

interface THistoryDialog {
  handleDialogOpen: Function;
  currentWellMap: string;
  currentTarget: string;
};

export default function HistoryDialog(props: THistoryDialog) {
  const {
    handleDialogOpen,
    currentWellMap,
    currentTarget,
  } = props;
  const {
    appState: {
      csvData: {
        editsHistory,
      },
    },
  } = useContext(AppContext);

  const {
    NOTES,
    ORIGINAL_RESULT,
    RESULT,
    TARGET,
    USER,
    UPLOAD_TIME,
    WELLMAP,
  } = RESULT_TABLE_DATA_KEYS;

  const {
    FINAL_RESULT_HEADER,
    QC_RESULT_HEADER,
  } = TABLE_HEADER_TITLES;

  const {
    RESULT: RESULT_VALUES,
    QC_CONTROL: CONTROL_VALUES,
  } = EDIT_SELECT_OPTIONS;

  const resultValuesArr = Object.values(RESULT_VALUES)
    .map(value => value.toLowerCase());
  const qcValuesArr = Object.values(CONTROL_VALUES)
    .map(value => value.toLowerCase());

  // console.log('!!!currentWellMap!!!', {
  //   currentWellMap,
  //   currentTarget,
  // });

  const editsArr: Record<string, string>[] = editsHistory?.editsHistory ? editsHistory.editsHistory
    .filter((edit: Record<string, string>) => (
        edit[WELLMAP] === currentWellMap && edit[TARGET] === currentTarget
      )
    ) : [];

  let resultType;

  if(editsArr.length && editsArr[0]?.result) {

    const currentKey = editsArr[0].result.toLowerCase();

    if(resultValuesArr.includes(currentKey)) {
      resultType = FINAL_RESULT_HEADER;
    } else if(qcValuesArr.includes(currentKey)) {
      resultType = QC_RESULT_HEADER;
    } else {
      resultType = FINAL_RESULT_HEADER;
    };
  }

  // console.log('!!Edits Arr', editsArr);

  return (
    <>
      <DialogTitle>
        Edit history
        <IconButton
          aria-label="close"
          onClick={() => handleDialogOpen({isOpen: false})}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Well Position</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Original Result</TableCell>
                  <TableCell>{resultType}</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Edit Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {editsArr.map((row: Record<string, any>, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{row[WELLMAP]}</TableCell>
                    <TableCell>{row[TARGET]}</TableCell>
                    <TableCell>{row[ORIGINAL_RESULT]}</TableCell>
                    <TableCell>{row[RESULT]}</TableCell>
                    <TableCell>{row[NOTES]}</TableCell>
                    <TableCell>{row[USER]}</TableCell>
                    <TableCell>{new Date(row[UPLOAD_TIME]).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
    </>
  );
};